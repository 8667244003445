import {makeAutoObservable} from "mobx";
import {environment} from "../../../env";
import {CategorizationReviewPageController} from "./CategorizationReviewPageController";
import ProfileStore from "../../../stores/ProfileStore";
import AuthStore from "../../../stores/AuthStore";
import {NavigateFunction} from "react-router-dom";

export type Page = 'welcome'
    | 'demo-ai-run'
    | 'review-dashboard'
    | 'review'
    | 'ai-busy'

/**
 Accessible by: `categorizationReviewStore.reviewPageController.reviewPageStateController`
 */
export class CategorizationReviewPageStateController {

    page: Page = environment.isTestReviewPage ? 'review' : 'welcome';

    constructor(
        private categorizationReviewPageController: CategorizationReviewPageController,
        private profileStore: ProfileStore,
        private auth: AuthStore,
    ) {
        makeAutoObservable(this)
    }

    get step(): number {
        switch (this.page) {
            default:
                return 0;
            case "review-dashboard":
                return 1;
            case "review":
                return 2;
        }
    }

    reset() {
        this.page = 'welcome';
    }

    setPage(page: Page) {
        this.page = page;
    }

    get previousLabel(): string | null {
        switch (this.page) {
            case "welcome":
            case "demo-ai-run":
            case "review-dashboard":
            case "review":
                return 'Back'
            default:
                return null
        }
    }

    onClickPrevious(navigate: NavigateFunction, backUrl: string) {
        switch (this.page) {
            case "welcome":
            case "demo-ai-run":
                navigate('' + backUrl);
                return
            case "review-dashboard":
            case "review":
                this.categorizationReviewPageController.navigateToPage('welcome')
                return;
        }
    }

    get nextLabel(): string | null {
        switch (this.page) {
            case "review-dashboard":
                return 'Next'
            default:
                return null;
        }
    }

    onClickNext() {
        switch (this.page) {
            case "welcome":
            case "demo-ai-run":
            case "review-dashboard":
                this.categorizationReviewPageController.navigateToPage('review')
                return
        }
    }
}