import {makeAutoObservable} from "mobx";
import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../components/currency-component/CurrencyClasses";
import {
    MatReviewLevelStatisticsTreeSerializer,
    MatReviewStatisticsSerializer
} from "../../../services/classes/MaterializedClasses";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import ProfileStore from "../../../stores/ProfileStore";
import {AxoisRequestManager} from "../../../stores/managers/RequestManager";
import {from} from "rxjs";
import {findUnclassified} from "../../../services/classes/CategorizationHelpers";
import {SummaryKeyValues} from "./CategorizationReviewPageController";

export class CategorizationReviewPageStatisticsController {
    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    reset() {
        this._reviewStatistics.cleanup()
        this._reviewLevelStatistics.cleanup()
    }

    request(bagId: number, businessUnitId: undefined | null | number, taxonomySize: number) {
        this._reviewStatistics.request({bagId})
        this._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}))
    }

    get isLoading(): boolean {
        return this._reviewStatistics.busy || this._reviewLevelStatistics.busy
    }

    get reviewLevelStatistics(): MatReviewLevelStatisticsTreeSerializer | undefined {
        return this._reviewLevelStatistics.result?.at(0);
    }

    get isReviewLevelStatisticsEmpty(): boolean {
        const r = this._reviewLevelStatistics.result;
        return r?.length === 0;
    }

    get unclassifiedStats(): MatReviewLevelStatisticsTreeSerializer | undefined | null {
        if (!this.reviewLevelStatistics) return undefined;
        return findUnclassified(this.reviewLevelStatistics)
    }

    get reviewStatistics(): MatReviewStatisticsSerializer | undefined | null {
        if (!this._reviewStatistics.result) return undefined;
        if (this._reviewStatistics.result.length !== 1) return null;
        return this._reviewStatistics.result.at(0);
    }

    get summaryResultKeyValues(): SummaryKeyValues | undefined {
        if (this.isReviewLevelStatisticsEmpty) {
            return {abbreviation: NO_ABBREVIATION, after: {classified_spend: 0, reclassified_spend: 0, unclassified_spend: 0}};
        }
        if (this.unclassifiedStats === undefined) {
            return undefined;
        }
        if (!this.reviewStatistics) return undefined;
        const unclassified_spend = this.unclassifiedStats?.values.post_spend || 0
        const reclassified_spend = this.reviewStatistics.cat_recat_spend || 0
        const classified_spend = this.reviewStatistics.cat_new_spend
        const after: SummaryKeyValues["after"] = {classified_spend, reclassified_spend, unclassified_spend}

        // These values should not have hardcoded abbreviations
        const abbreviation: CurrencyAbbreviation = findAbbreviationOfGroup(Object.values(after));

        return {abbreviation, after}
    }

    readonly _reviewStatistics = new AxoisRequestManager<{
        bagId: number
    }, MatReviewStatisticsSerializer[]>(
        ({bagId}) => from(this.api.listReviewStatistics(bagId)),
    );
    readonly _reviewLevelStatistics = new AxoisRequestManager<{
        bagId: number,
        businessUnitId: undefined | null | number,
        taxonomySize: number
    }, [MatReviewLevelStatisticsTreeSerializer] | []>(
        ({
             bagId,
             businessUnitId,
             taxonomySize
         }) => from(this.api.listReviewLevelStatistics(bagId, businessUnitId, taxonomySize))
    );
}