import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export const INITIATIVE_COLUMNS: Array<ColSpec> = [
    {cls: '', txt: 'Title', columnFieldId: 'title', minWidth: 240},
    {cls: '', txt: 'Owner', columnFieldId: 'owner__email'},
    {cls: '', txt: 'Category', columnFieldId: 'financial__categories__l1'},
    {cls: '', txt: 'Lever group', columnFieldId: 'lever__group__title'},
    {cls: '', txt: 'Traffic light', columnFieldId: ''},
    {cls: '', txt: 'Stage', columnFieldId: 'doi_status'},
    {cls: '', txt: 'Cost Savings', columnFieldId: 'financial__saving_amount'},
    {cls: '', txt: 'Cost Avoidance', columnFieldId: 'financial__avoidance_amount'},
    {cls: '', txt: 'Last Modified', columnFieldId: 'updated'},
    {cls: '', txt: 'Modified By', columnFieldId: 'responsible__email'},
]

export const INITIATIVE_APPROVAL_COLUMNS: Array<ColSpec> = [
    {cls: '', txt: 'Title', minWidth: 240},
    {cls: '', txt: 'Owner'},
    {cls: '', txt: 'Category'},
    {cls: '', txt: 'Lever group'},
    // {cls: '', txt: 'Traffic light'},
    {cls: '', txt: 'Stage'},
    {cls: '', txt: 'Cost Savings'},
    {cls: '', txt: 'Cost Avoidance'},
    {cls: '', txt: 'Finance Approver'},
    {cls: '', txt: 'Status'},
    {cls: '', txt: 'Last Modified'},
    {cls: '', txt: 'Modified By'},
]
