import {MatSGroup, MatSGroupAggregate, ReviewChoice} from "../../../services/classes/MaterializedClasses";
import {makeAutoObservable} from "mobx";
import {Categories} from "../../../services/classes/AiClasses";
import {buildCombinedPartReviewStateVisual, CombinedPartReviewStateVisual} from "./CategorizationReviewVisualStates";
import {PartRowState} from "./PartRowState";
import {MatSupplierFilter} from "../../../services/classes/MatReviewClasses";
import {stores} from "../../../stores";
import {environment} from "../../../env";
import ProfileStore from "../../../stores/ProfileStore";
import AuthStore from "../../../stores/AuthStore";
import MithraMaterializedApi, {Ordering} from "../../../services/MithraMaterializedApi";

export class GroupedRowState {
    open = false;
    isLoadingRows = false;
    page = 1
    pageSize = stores.p.p.categorizationSublistPageSize ?? environment.defaultCategorizationSublistPageSize;

    /**
     * If everything is selected
     * including the parts not visible in the current page
     */
    isAllSelected = false;

    get id(): number {
        return this.data.supplier_id;
    }

    get supplierId(): number {
        return this.data.supplier_id;
    }

    get partCount() {
        return this.data.n_parts || 0;
    }

    get spend() {
        return this.data.sum_spend;
    }

    constructor(
        /**
         * Shows information about the combined state of the parts below this supplier
         *
         * When the part data of this row changes, the combined state requires recalculation, in the meantime this will be undefined
         */
        public combinedStateVisual: CombinedPartReviewStateVisual,
        public combinedStateLoading: boolean,
        public currentPageNumber: number,
        public partStates: PartRowState[] | undefined,
        public data: MatSGroup,
        public filter: MatSupplierFilter | undefined,
    ) {
        makeAutoObservable(this)
    }

    public static build(data: MatSGroup, pageNumber: number, filter?: MatSupplierFilter) {
        // console.debug('Building grouped row state', {supplier: data.supplier_id, pageNumber});
        return new GroupedRowState(
            buildCombinedPartReviewStateVisual(data),
            false,
            pageNumber,
            undefined,
            data,
            filter,
        );
    }

    public setOpen(open: boolean) {
        this.open = open;
        this.partStates = []
        if (open) {
            this.handlePageChange(1)
        }
    }

    public setIsLoading(isLoading: boolean) {
        this.isLoadingRows = isLoading;
    }

    setPartStates(partStates: PartRowState[], pageNumber: number) {
        if (this.isAllSelected) {
            partStates.forEach(p => p.selected = true);
        }
        this.partStates = partStates;
        this.page = pageNumber;
        this.isLoadingRows = false;
    }

    get hasPartsOutsideView(): boolean {
        return this.partCount > this.pageSize;
    }

    get isAllInViewSelected(): boolean {
        if (!this.partStates) return false;
        if (this.partStates.length === 0) return false;
        return this.nInViewSelected === this.partStates.length;
    }

    /**
     * The number of selected rows in the current page
     *
     * This is the only 'expensive' calculation in this class as it loops over all the parts
     * However it's cached by mobx
     */
    get nInViewSelected(): number {
        return this.partStates?.filter(p => p.selected).length ?? 0;
    }

    get hasSelection(): boolean {
        return this.isAllSelected || this.nInViewSelected > 0;
    }

    /**
     * The total number of selected rows
     */
    get nSelected(): number {
        if (this.isAllSelected) {
            return this.partCount;
        }
        return this.nInViewSelected;
    }

    get selectionState(): 'all' | 'none' | 'some' {
        if (this.isAllSelected) {
            return 'all'
        }
        if (!this.hasPartsOutsideView && this.isAllInViewSelected) {
            // this.isAllSelected should have been set already
            console.warn('The subRow was changed, but `reCalcAllSelected` is not called')
            return 'all';
        }
        if (this.nInViewSelected === 0) {
            return 'none';
        }
        return 'some';
    }

    public reCalcAllSelected(newSelection: boolean) {
        if (!newSelection) {
            // A part is deselected
            this.isAllSelected = false;
        } else {
            // A part is selected
            if (this.hasPartsOutsideView) {
                // We do not allow the user to select parts outside the view indirectly
                // This needs to be done by selecting everything explicitly
                this.isAllSelected = false;
            } else {
                this.isAllSelected = this.isAllInViewSelected;
            }
        }
    }

    public handlePageChange(newPage: number, ordering?: Ordering) {
        // Fetch data for the new page
        this.setIsLoading(true)

        const profileStore: ProfileStore = stores.p;
        const authStore: AuthStore = stores.authStore;
        const api: MithraMaterializedApi = stores.materializedApi;

        const userId = authStore.userId;
        api.listPartsReviewInGroup(
            this.data.mat_s_group_ids,
            newPage,
            this.pageSize,
            profileStore.p.categorizationReviewSubRowRelationData,
            ordering,
        ).then(resp => {
            const partStates = resp.data.results.map(p => PartRowState.build(p, userId, this));
            this.setPartStates(partStates, newPage);
        }).finally(() => {
            this.setIsLoading(false)
        })
    };

    /**
     * When changing the review state
     * @param reviewChoice
     */
    public updateLocalOnReviewChange(reviewChoice: ReviewChoice): void {
        console.log(`Updating supplier ${this.supplierId} with ${this.partCount} parts to review ${reviewChoice}`);

        // TODO: How do we show the loading state?
        this.combinedStateLoading = true;

        const p = this.data;
        const predictedAggregatedData: MatSGroupAggregate = {
            sum_spend: p.sum_spend,
            n_parts: p.n_parts,
            all_category_is_uncat: false,
            category: p.category,
            reviewed_by_other: false,
            exists_invalid_suggestion: p.exists_invalid_suggestion,
            all_invalid_suggestion: p.all_invalid_suggestion,
            exists_open_suggestion: p.exists_open_suggestion,
            all_is_reviewed: p.all_is_reviewed,
            exists_approval_set: p.exists_approval_set,
            exists_approval_applied: p.exists_approval_applied,
            all_approval_set: p.all_approval_set,
            all_approval_applied: p.all_approval_applied,
            all_feedback_choice_reject: p.all_feedback_choice_reject,
            all_feedback_choice_accept: p.all_feedback_choice_accept,
            supplier_id: p.supplier_id,
            mat_s_group_ids: p.mat_s_group_ids
        }

        // Note: We do not touch the data field, as it will be updated by the API asynchronously
        this.combinedStateVisual = buildCombinedPartReviewStateVisual(predictedAggregatedData);

        this.partStates?.forEach(p => p.updateLocalOnReviewChange(reviewChoice))
    }

    /**
     * When changing category
     * @param category
     * @param reviewChoice
     */
    public updateLocalOnCategoryChange(category: Categories, reviewChoice: ReviewChoice): void {
        console.log(`Updating supplier ${this.supplierId} with ${this.partCount} parts to category ${category}`);

        this.combinedStateLoading = true;

        const p: MatSGroupAggregate = this.data;
        const predictedAggregatedData: MatSGroupAggregate = {
            sum_spend: p.sum_spend,
            n_parts: p.n_parts,
            all_category_is_uncat: false,
            category: category,
            reviewed_by_other: false,
            exists_invalid_suggestion: p.exists_invalid_suggestion,
            all_invalid_suggestion: p.all_invalid_suggestion,
            exists_open_suggestion: p.exists_open_suggestion,
            all_is_reviewed: p.all_is_reviewed,
            exists_approval_set: p.exists_approval_set,
            exists_approval_applied: p.exists_approval_applied,
            all_approval_set: p.all_approval_set,
            all_approval_applied: p.all_approval_applied,
            all_feedback_choice_reject: p.all_feedback_choice_reject,
            all_feedback_choice_accept: p.all_feedback_choice_accept,
            mat_s_group_ids: p.mat_s_group_ids,
            supplier_id: p.supplier_id
        }

        // Note: We do not touch the data field, as it will be updated by the API asynchronously
        this.combinedStateVisual = buildCombinedPartReviewStateVisual(predictedAggregatedData);

        this.partStates?.forEach(p => p.updateLocalOnCategoryChange(category, reviewChoice))
    }

    public updateData(supplierRowData: MatSGroup) {
        this.data = supplierRowData;
        this.combinedStateVisual = buildCombinedPartReviewStateVisual(supplierRowData);
        this.combinedStateLoading = false;
    }

    isOpenToReview() {
        // TODO: This method is not implemented yet

        // TODO: [CAT-1082] Here we duplicate the logic that the BE uses to determine if a part is open for review
        // We need to place another field in the backend to allow this; `is_open_to_review`
        // Now it will not be updated in the backend, so it's better to now allow update in the FE as well

        return true;
    }

    isOpenToCategoryChange() {
        // TODO: This method is not implemented yet
        return true;
    }
}

