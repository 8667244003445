import {Category, Cluster, Company, Division, SimpleSitUserProfile, Target} from "../ApiTypes";
import {makeAutoObservable} from "mobx";
import dayjs, {Dayjs} from "dayjs";
import {CompanyConfigurationStore} from "./CompanyConfigurationStore";
import SitApi from "../SitApit";
import ProfileStore from "../../stores/ProfileStore";
import AuthStore from "../../stores/AuthStore";

const financial_amount_attrs = ['baseline_spend', 'saving_amount', 'avoidance_amount',
    'fiscal_year_saving_amount', 'fiscal_year_avoidance_amount',
]

export class TargetInstanceStore {
    instance: Partial<Target> = {};

    constructor(private api: SitApi,
                private ccStore: CompanyConfigurationStore,
                public authStore: AuthStore,
                public profileStore: ProfileStore) {
        makeAutoObservable(this);
    }

    setInstance(value: Partial<Target>) {
        const profile = this.authStore.sitUserProfile;
        if (!profile) return;

        this.instance = value;
        // Default values
        if (this.instance.target_year == null)
            this.instance.target_year = new Date().getFullYear();
        if (!this.instance.owner)
            this.instance.owner = profile;
        if (!this.instance.currency_id)
            this.instance.currency_id = profile.default_company?.currency.id ??
            this.ccStore.getCurrencyId(this.profileStore.currencySymbol ?? '') ?? 0
        if (this.profileStore.currencySymbol &&
            this.ccStore.getCurrencyCode(this.instance.currency_id ?? 0, '') !== this.profileStore.currencySymbol) {
            financial_amount_attrs.forEach((attr) => {
                this.instance[attr] = this.ccStore.convertToCurrency(
                    this.instance[attr] ?? 0, this.instance.currency_id!, this.profileStore.currencySymbol!) / 1000 ;
            });
        }
    }

    createOrUpdate() {
        let copyInstance: Partial<Target> = {};
        Object.assign(copyInstance, this.instance);
        if (this.profileStore.currencySymbol && this.instance.currency_id) {
            financial_amount_attrs.forEach((attr) => {
                copyInstance[attr] = parseFloat((1000 * this.ccStore.convertFromCurrency(
                    this.instance[attr] ?? 0, this.profileStore.currencySymbol!, this.instance.currency_id!
                )).toFixed(4));
            });
        }
        if (!this.instance.id)
            return this.api.createTarget(copyInstance);
        else
            return this.api.updateTarget(copyInstance);
    }

    set title(value: string) {
        this.instance.title = value
    }

    get targetYear() {
        return dayjs(new Date(this.instance.target_year ?? new Date().getFullYear(), 1, 1));
    }

    set targetYear(value: Dayjs) {
        this.instance.target_year = value.toDate().getFullYear();
    }

    set currencyId(value: number) {
        this.instance.currency_id = value;
    }

    set baselineSpend(value: number) {
        this.instance.baseline_spend = value;
    }

    set savingAmount(value: number) {
        this.instance.saving_amount = value;
    }

    set avoidanceAmount(value: number) {
        this.instance.avoidance_amount = value;
    }

    set local(value: boolean) {
        this.instance.local = value;
    }

    get combinedClusters(): Cluster[] {
        return this.ccStore.companyRM.result?.filter(
            (company) => (this.instance.companies?.indexOf(company.id) ?? -1) > -1
        ).map(
            (company) => company.cluster
        ).filter(
            (value, index, array) => array.map((cluster) => cluster.id).indexOf(value.id) === index
        ) ?? [];
    }

    isCheckedClusters(id: number) {
        const allCompanyIds = this.ccStore.companyRM.result?.filter(
            (company) => company.cluster.id === id
        ).map(
            (company) => company.id
        ) ?? [];
        return allCompanyIds.length > 0 && (allCompanyIds.every(
            (company_id) => (this.instance.companies?.indexOf(company_id) ?? -1) > -1
        ) ?? false);
    }

    isIndeterminateCluster(id: number) {
        const allCompanyIds = this.ccStore.companyRM.result?.filter(
            (company) => company.cluster.id === id
        ).map(
            (company) => company.id
        ) ?? [];
        return allCompanyIds.filter((id) => (this.instance.companies?.indexOf(id) ?? -1) > -1).length > 0 &&
            allCompanyIds.filter((id) => (this.instance.companies?.indexOf(id) ?? -1) === -1).length > 0;
    }

    setClusters(value: Cluster[], remove: boolean) {
        if (remove) {
            const removedClusterIds = this.combinedClusters.filter(
                (cluster) => !(value.map((c) => c.id).includes(cluster.id))
            ).map(
                (cluster) => cluster.id
            );
            this.ccStore.companyRM.result?.filter(
                (company) => removedClusterIds.includes(company.cluster.id)
            ).forEach(
                (item) => this.instance.companies?.splice(this.instance.companies?.indexOf(item.id), 1)
            )
        } else {
            const addedClusterIds = value.filter(
                (cluster) => !(this.combinedClusters.map((c) => c.id).includes(cluster.id))
            ).map(
                (cluster) => cluster.id
            );
            this.instance.companies = this.ccStore.companyRM.result?.filter(
                (company) => addedClusterIds.includes(company.cluster.id) || this.instance.companies?.includes(company.id)
            ).map(
                (cluster) => cluster.id
            ).filter(
                (value, index, array) => array.indexOf(value) === index
            );
        }
    }

    get companyValues(): Company[] {
        return this.ccStore.companyRM.result?.filter(
            (company) => (this.instance.companies?.indexOf(company.id) ?? -1) > -1
        ) ?? [];
    }

    set companies(value: number[]) {
        this.instance.companies = value;
    }

    get categoryValues(): Category[] {
        return this.ccStore.categoryRM.result?.filter(
            (category) => (this.instance.categories?.indexOf(category.id) ?? -1) > -1
        ) ?? [];
    }

    set categories(value: number[]) {
        this.instance.categories = value;
    }

    get divisionValues(): Division[] {
        return this.ccStore.divisionRM.result?.filter(
            (division) => (this.instance.divisions?.indexOf(division.id) ?? -1) > -1
        ) ?? [];
    }

    set divisions(value: number[]) {
        this.instance.divisions = value;
    }

    set owner(value: SimpleSitUserProfile) {
        this.instance.owner = value;
    }

    set approver(value: SimpleSitUserProfile) {
        this.instance.approver = value;
    }
}
