import {makeAutoObservable} from "mobx";
import {analyticsSearchEvent} from "../../services/analytics/AnalyticEvents";

export type SearchTrigger = 'click' | 'enter' | 'auto-reset';

export class SearchManager {
    searchString = ''
    activeSearchString = ''

    constructor(
        /**
         * Used for analytics
         */
        public searchTypeTag: string,
        /**
         * This method is called when the user wants to search
         */
        private readonly onSearch: (string) => void,
        // public doNotClearSearch?: true
    ) {
        makeAutoObservable(this, {searchTypeTag: false})
    }

    resetSearch() {
        this.searchString = ''
        this.activeSearchString = ''
    }

    setSearch(search: string) {
        this.searchString = search;
    }

    clearSearchBox() {
        this.searchString = '';
        this.activeSearchString = '';
    }

    clearSearch() {
        this.setSearch('')
        const changed = this.activeSearchString !== ''
        this.activeSearchString = '';
        if (changed) {
            this.onSearch(this.activeSearchString)
        }
        return changed;
    }

    doSearch(trigger: SearchTrigger) {
        this.activeSearchString = this.searchString.toLowerCase();

        analyticsSearchEvent({
            search_term: String(this.activeSearchString),
            trigger: trigger,
            search_type: String(this.searchTypeTag),
        });

        this.onSearch(this.activeSearchString)
    }
}