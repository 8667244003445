import {DEV_USER_DUMMY, environment} from "../../env";
import {makeAutoObservable} from "mobx";
import {MithraLoginResponse} from "../../services/ApiTypes";
import {AxiosError, AxiosInstance} from "axios";
import {CodeResponse, googleLogout} from "@react-oauth/google";
import AuthApi from "../../services/AuthApi";
import {AlertColor} from "@mui/material";
import {User} from "../../services/classes/User";
import {Location} from "history";
import {routes} from "../../routing/routes";
import {GetTokenSilentlyOptions, LogoutOptions} from "@auth0/auth0-react";
import {analyticsLoginEvent, analyticsLogoutEvent} from "../../services/analytics/AnalyticEvents";
import {NavigateFunction} from "react-router-dom";

export type LoginRouteState = {
    state: 'login'
    sourceUrl: string
}

export type GoogleAuthorizationResponse = Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>;
type AlertMsg = undefined | { msg: string, severity: AlertColor };

type MithraAuthToken = {
    access_token: string
    refresh_token: string
}

const SESSION_STORAGE_PREFIX = 'auth.v2.';

export class AuthenticationManager {
    authenticatedUser: User | undefined
    isLoggingIn = false
    isExpired = false
    alert: AlertMsg = undefined

    // user: Partial<User> | undefined
    mithraAuthToken: MithraAuthToken | undefined

    constructor(
        private api: AuthApi,
    ) {
        if (environment.devLogin) {
            this.authenticatedUser = DEV_USER_DUMMY
            this.mithraAuthToken = {access_token: 'DEV', refresh_token: 'DEV'}
        } else {
            // if (sessionStorage.getItem(SESSION_STORAGE_PREFIX + 'user')) {
            //     this.user = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_PREFIX + 'user') as string)
            // }
            // Retrieve session storage
            if (sessionStorage.getItem(SESSION_STORAGE_PREFIX + 'access_token')
                && sessionStorage.getItem(SESSION_STORAGE_PREFIX + 'refresh_token')) {
                this.mithraAuthToken = {
                    access_token: sessionStorage.getItem(SESSION_STORAGE_PREFIX + 'access_token') as string,
                    refresh_token: sessionStorage.getItem(SESSION_STORAGE_PREFIX + 'refresh_token') as string,
                };
            }
        }
        makeAutoObservable(this);
    }

    setAuthenticatedUser(user: User) {
        this.authenticatedUser = user;
        sessionStorage.setItem(SESSION_STORAGE_PREFIX + 'user', JSON.stringify(this.authenticatedUser));
        analyticsLoginEvent({
            mithra_id: this.authenticatedUser.email || this.authenticatedUser.username || String(this.authenticatedUser.id),
            email_domain: this.authenticatedUser.email.split('@')[1] || '',
            is_mithra_user: '@mithra-ai.com' === this.authenticatedUser.email.slice(-14) ? 'true' : 'false',
            is_dennis_user: 'dennis@mithra-ai.com' === this.authenticatedUser.email ? 'true' : 'false',
        })
    }

    onChangeUserData(user: User) {
        if (!this.authenticatedUser || this.authenticatedUser.id !== user.id)
            throw new Error('The user was not authenticated');
        this.authenticatedUser = user;
        sessionStorage.setItem(SESSION_STORAGE_PREFIX + 'user', JSON.stringify(this.authenticatedUser));
    }

    setToken(serverResp: MithraLoginResponse) {
        this.mithraAuthToken = {
            access_token: serverResp.access_token,
            refresh_token: serverResp.refresh_token,
        };
        sessionStorage.setItem(SESSION_STORAGE_PREFIX + 'access_token', this.mithraAuthToken.access_token);
        sessionStorage.setItem(SESSION_STORAGE_PREFIX + 'refresh_token', this.mithraAuthToken.refresh_token);
    }

    logout() {
        analyticsLogoutEvent();
        sessionStorage.clear();
        this.authenticatedUser = undefined;
        this.mithraAuthToken = undefined;
        googleLogout();
    }

    auth0Logout(auth0LogoutHandle: (options: LogoutOptions | undefined) => void) {
        sessionStorage.clear();
        this.authenticatedUser = undefined;
        this.mithraAuthToken = undefined;
        auth0LogoutHandle({
            logoutParams: {
                returnTo: environment.baseUrl
            }
        });
    }

    catchApiError(err: AxiosError<any>) {
        if (isTokenExpired(err)) {
            console.debug('AuthManager: Token expired, logging out');
            this.isExpired = true;
            this.logout();
            return false;
        }
        return true;
    }

    onGoogleAuthorizeSuccess(codeResponse: GoogleAuthorizationResponse, location: Location, navigate: NavigateFunction) {
        console.debug('AuthManager: User authenticated via Google');
        this.api.loginToMithraAsGoogleUser(codeResponse)
            .then(resp => this.processMithraLoginSuccess(resp.data, location, navigate))
            .catch(err => this.processMithraLoginError(err))
            .finally(() => this.processMithraLoginFinal())
    }

    onAuth0AuthorizeSuccess(
        getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>,
        location: Location, navigate: NavigateFunction,
    ) {
        console.debug('AuthManager: User authenticated via Auth0');
        getAccessTokenSilently()
            .then(temp_access_token => {
                this.api.loginToMithraAsAuth0User(temp_access_token)
                    .then(resp => this.processMithraLoginSuccess(resp.data, location, navigate))
                    .catch(err => this.processMithraLoginError(err))
                    .finally(() => this.processMithraLoginFinal())
            })
            .catch(err => {
                this.alert = {msg: 'Login failed', severity: 'error'};
                this.processMithraLoginFinal();
                console.error('AuthManager: getAccessTokenSilently failed', err);
            });
    }

    processMithraLoginSuccess(resp: MithraLoginResponse, location: Location<any>, navigate: NavigateFunction) {
        console.debug('AuthManager: Mithra authentication successful')
        this.setToken(resp);
        this.setAuthenticatedUser(resp.user);
        this.alert = undefined;
        this.isExpired = false;
        this.isLoggingIn = false;

        let redirect = routes.home;
        if (location.state?.state === 'login') {
            const s: LoginRouteState = location.state;
            redirect = s.sourceUrl;
        }
        if (redirect === routes.login) {
            // Prevent redirect loop
            redirect = routes.home;
        }

        navigate(redirect);
    }

    processMithraLoginError(reason) {
        let alertMsg = '';
        if (reason.response) {
            if (reason.response.data) {
                if (reason.response.data.detail) {
                    alertMsg = `${reason.response.data.detail}`;
                }
                if (!alert && reason.response.data.non_field_errors) {
                    alertMsg = `${reason.response.data.non_field_errors}`;
                }
            }
            if (!alertMsg) {
                alertMsg = `${reason.response.statusText}`;
            }
        }
        if (!alertMsg) {
            alertMsg = reason.message || reason || 'Login failed';
            alertMsg = `${alertMsg}, please try again`
        }
        this.alert = {msg: alertMsg, severity: 'error'}
        console.error('AuthManager: Mithra login failed', alertMsg);
    }

    processMithraLoginFinal() {
        this.isLoggingIn = false;
    }

    public wrapAuthorized(...axios: AxiosInstance[]) {
        const interceptor = config => {
            if (this.mithraAuthToken) {
                config.headers['Authorization'] = `Bearer ${this.mithraAuthToken.access_token}`;
            }
            return config;
        };
        for (const http of axios) {
            http.interceptors.request.use(interceptor);
        }
    }

    startLogin() {
        this.authenticatedUser = undefined;
        this.isLoggingIn = true;
    }

    startGoogleLogin(googleLogin: () => void) {
        this.startLogin();
        googleLogin();
    }

    startAuth0Login(auth0Login: () => void) {
        this.authenticatedUser = undefined;
        this.isLoggingIn = true;
        auth0Login();
    }
}

/**
 * Verify a Mithra API Response token
 * @param err
 */
function isTokenExpired(err: AxiosError<any>) {
    if (err.response) {
        const resp = err.response;
        if (resp.status === 401 && resp.data.code === 'token_not_valid') {
            return true;
        }
    }
    return false;
}
