import {observer} from "mobx-react-lite";
import {HistoryButtons} from "./HistoryButtons";
import {Button, Grow, Popper} from "@mui/material";
import {
    ArrowRightAlt,
    AutoMode,
    CallMerge,
    Check,
    ChevronRight,
    CloudDownload,
    DeleteOutline,
    Description,
    Done,
    Edit,
    LocalHospital,
    Sync,
    UnfoldLess,
    UnfoldMore
} from "@mui/icons-material";
import React, {useState} from "react";
import {useStores} from "../../stores";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {JobRouteMatch, routes} from "../../routing/routes";
import {environment} from "../../env";
import {generateGotoTaxonomySuggestor} from "../../routing/routing.utils";
import {analyticsExportEvent} from "../../services/analytics/AnalyticEvents";


export const TaxonomyEditorButtonBar = observer(() => {
    const {
        rootAppStore,
        p,
        authStore,
        taxonomyEditorStore,
        taxonomyManagerStore,
        materializedApi,
        bagStore,
        taxonomyHealthCheckStore,
    } = useStores();
    const params = useParams<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleCloseSettings = () => {
        setAnchorEl(null);
    };

    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false)
    const [hasDownloadedExcel, setHasDownloadedExcel] = useState(false)

    const allButtonsDisabled = !taxonomyManagerStore.canEditTaxonomy;

    // FIXME: We have a problem in the back button and the router.
    const showAiTaxonomySuggestionButton = p.p.useAiTaxonomySuggestion &&
        (
            (environment.package === 'cleanup') ||
            (environment.package === 'merge_x' && rootAppStore.app.dashboardEnvironment === 'merge') ||
            (environment.package === 'merge_2' && rootAppStore.app.dashboardEnvironment === 'merge')
        );

    const showDemoTaxonomySuggestion = p.p.demoFakeTaxonomySuggestion && (
        environment.package === 'sales_demo' && rootAppStore.app.dashboardEnvironment === 'cleanup'
    );

    const taxonomyHealthCheckUrl =
        (environment.package === 'sales_demo') ? routes.demo_cleanup_taxonomy_health_check :
            rootAppStore.app.dashboardEnvironment === 'merge' ? routes.merge_x_taxonomy_health_check : '';

    return <div id="taxonomy-editor-button-toolbar">
        {!taxonomyEditorStore.isEditMode &&
            <>
                {/*TODO: Add a reset button maybe?*/}
                {/*<Button*/}
                {/*    startIcon={taxonomyEditorStore.isFullViewMode ? <UnfoldLess/> : <UnfoldMore/>}*/}
                {/*    onClick={() => taxonomyEditorStore.toggleViewMode()}>*/}
                {/*    {taxonomyEditorStore.isFullViewMode ? 'Fit to screen' : 'Expand'}*/}
                {/*</Button>*/}
                <Button
                    startIcon={taxonomyEditorStore.isFullViewMode ? <UnfoldLess/> : <UnfoldMore/>}
                    onClick={() => taxonomyEditorStore.toggleViewMode()}>
                    {taxonomyEditorStore.isFullViewMode ? 'Fit to screen' : 'Expand'}
                </Button>
            </>
        }
        {taxonomyEditorStore.isEditMode ? <HistoryButtons/> : undefined}
        {p.p.useAiTaxonomyHealthCheck && <Button
            color="primary"
            variant="contained"
            className="toolbar-btn large-icon-btn"
            startIcon={<LocalHospital/>}
            disabled={environment.isDemo
                ? (!taxonomyHealthCheckStore.hasHealthCheck) // We disable this in the demo to show in which databag we have a health check and not...
                : taxonomyEditorStore.disableHealth
            }
            onClick={() => {
                if (environment.isDemo) {
                    // Demo variant:
                    navigate(generatePath(taxonomyHealthCheckUrl, {
                        id: bagId,
                        taxonomyId: p.p.hardcodedOriginalTaxonomyId || -1,
                    }))
                } else {
                    // Real variant:
                    const taxonomyId = taxonomyManagerStore.taxonomy?.id;
                    if (!taxonomyId) return;
                    taxonomyEditorStore.setHealthButtonText("Health is being created...")
                    taxonomyEditorStore.setDisableHealth(true)
                    taxonomyHealthCheckStore.createNewTaxonomyHealthCheck(taxonomyId).then(() => {
                        // We have successfully created a new taxonomy health check
                        taxonomyManagerStore.requestTaxonomy(taxonomyId)
                        taxonomyHealthCheckStore.getTaxonomyHealthCheck(taxonomyId).then(() => {
                            taxonomyManagerStore.overwriteTaxonomyHealthCheckResult(true)
                            taxonomyEditorStore.setHealthButtonText("Health")
                            taxonomyEditorStore.setDisableHealth(false)
                            navigate(generatePath(routes.merge_x_taxonomy_health_check, {id: bagId, taxonomyId,}))
                        }).catch(() => {
                            taxonomyEditorStore.setHealthButtonText("Health")
                            taxonomyEditorStore.setDisableHealth(false)
                        });
                    }).catch(() => {
                        taxonomyEditorStore.setHealthButtonText("Health")
                        taxonomyEditorStore.setDisableHealth(false)
                    });
                }
            }}>
            {taxonomyEditorStore.healthButtonText}
        </Button>}
        {showAiTaxonomySuggestionButton && <Button
            color="primary"
            variant="contained"
            className="toolbar-btn large-icon-btn"
            onClick={() => {
                navigate(generateGotoTaxonomySuggestor(bagId, p.p.hardcodedOriginalTaxonomyId || -1))
            }}>
            AI suggestions
        </Button>}
        {showDemoTaxonomySuggestion && <Button
            color="primary"
            variant="outlined"
            className="toolbar-btn large-icon-btn"
            startIcon={<AutoMode/>}
            onClick={() => {
                // Demo variant
                navigate(generatePath(routes.demo_cleanup_taxonomy_suggestion_v1, params))
            }}>
            Generate Suggestion
        </Button>}
        {/*<Button*/}
        {/*    color="primary"*/}
        {/*    variant="outlined"*/}
        {/*    className="toolbar-btn large-icon-btn"*/}
        {/*    startIcon={<ChevronLeft/>}*/}
        {/*    disabled={!taxonomyEditorStore.canGoUp}*/}
        {/*    onClick={() => taxonomyEditorStore.goUpLevel()}>*/}
        {/*    {taxonomyEditorStore.goBackButtonTxt}*/}
        {/*</Button>*/}
        <Button
            className="toolbar-btn large-icon-btn btn-red"
            color="primary"
            variant="outlined"
            startIcon={<DeleteOutline/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length === 0}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.deleteSelection()}>
            Delete
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<CallMerge style={{transform: 'rotate(90deg)'}}/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length !== 2}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.setMergeCategoryMode(true)}>
            Merge
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<ArrowRightAlt/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length === 0}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.setMoveCategoryMode(true)}>
            Move
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<Description/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length !== 1}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.setUpdateCategoryMode(true)}>
            Rename
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<Edit/>}
            disabled={allButtonsDisabled || authStore.viewOnly}
            onClick={() => taxonomyEditorStore.toggleEditMode()}>
            {taxonomyEditorStore.isEditMode ? 'Finish editing' : 'Edit'}
        </Button>

        {/*<Button*/}
        {/*    disabled={!taxonomyManagerStore.taxonomy}*/}
        {/*    className="toolbar-btn large-icon-btn two-icon-btn"*/}
        {/*    color="primary"*/}
        {/*    variant="outlined"*/}
        {/*    startIcon={<SettingsApplicationsOutlined/>}*/}
        {/*    endIcon={open ? <ExpandLess/> : <ExpandMore/>}*/}
        {/*    onClick={e => handleClickSettings(e, open)}*/}
        {/*    aria-haspopup={true}*/}
        {/*    aria-describedby="taxonomy-editor-button-settings">*/}
        {/*    <div className="btn-text">*/}
        {/*        /!*{open ? 'Close settings' : 'View settings'}*!/*/}
        {/*        Settings*/}
        {/*    </div>*/}
        {/*</Button>*/}
        <Popper
            id="taxonomy-editor-button-settings-popper"
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            transition
            disablePortal
        >
            {({TransitionProps, placement}) =>
                // Collapse is not possible: https://github.com/mui/material-ui/issues/11337
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <div className="overlay-container">
                        <Button
                            disabled
                            className="toolbar-btn large-icon-btn"
                            color="primary"
                            variant="outlined"
                            startIcon={<Description/>}
                            onClick={handleCloseSettings}>
                            <div className="btn-text">
                                View<br/> change log
                            </div>
                        </Button>
                        <Button
                            disabled
                            className="toolbar-btn large-icon-btn"
                            color="primary"
                            variant="outlined"
                            startIcon={<Sync/>}
                            onClick={handleCloseSettings}>
                            <div className="btn-text">
                                Calculate<br/> new amounts
                            </div>
                        </Button>
                        <Button
                            disabled
                            className="toolbar-btn large-icon-btn"
                            color="primary"
                            variant="outlined"
                            startIcon={<Check/>}
                            onClick={handleCloseSettings}>
                            <div className="btn-text">
                                Submit for approval
                            </div>
                        </Button>
                    </div>
                </Grow>
            }
        </Popper>
        {/*<Button*/}
        {/*    disabled={!taxonomyManagerStore.taxonomy}*/}
        {/*    className="toolbar-btn large-icon-btn"*/}
        {/*    color="primary"*/}
        {/*    variant="outlined"*/}
        {/*    startIcon={<Edit/>}*/}
        {/*    onClick={() => taxonomyEditorStore.toggleEditMode()}>*/}
        {/*    {taxonomyEditorStore.isEditMode ? 'Finish editing' : 'Edit'}*/}
        {/*</Button>*/}
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={isDownloadingExcel ? undefined : (hasDownloadedExcel ? <Done/> :
                <CloudDownload/>)}
            endIcon={isDownloadingExcel ? undefined : (hasDownloadedExcel ? undefined : undefined)}
            disabled={allButtonsDisabled || isDownloadingExcel}
            style={taxonomyEditorStore.isEditMode ? {display: 'none'} : {minWidth: '10em'}}
            onClick={() => {
                const taxonomyId = taxonomyManagerStore.taxonomy?.id;
                if (taxonomyId) {
                    setIsDownloadingExcel(true)
                    analyticsExportEvent({
                        export_type: 'taxonomy',
                        object_key: String(taxonomyId),
                        user: authStore.email
                    })
                    const filename = 'Taxonomy.xlsx';
                    materializedApi.downloadTaxonomyExcel(taxonomyId, filename)
                        .then(resp => {
                            const url = URL.createObjectURL(new Blob([resp.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                            setHasDownloadedExcel(true)
                        })
                        .finally(() => {
                            setIsDownloadingExcel(false)
                        })
                }
            }}>
            {isDownloadingExcel ? 'Downloading ...' : 'Download'}
        </Button>
        {!p.p.hideApprovalButtons && <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            disabled={!taxonomyManagerStore.canGoToApproval}
            style={taxonomyEditorStore.isEditMode ? {display: 'none'} : {}}
            onClick={() => taxonomyEditorStore.setPage('approval')}
            endIcon={<ChevronRight/>}
        >
            Approval
        </Button>}
    </div>;

    // return (
    //     <div id="taxonomy-editor-button-toolbar">
    //         {taxonomyEditorStore.isEditMode ? <HistoryButton/> : undefined}
    //         {showAiTaxonomySuggestionButton && (
    //             <IconButton
    //                 color="primary"
    //                 // className="toolbar-btn large-icon-btn"
    //                 size={'large'}
    //                 onClick={() => {
    //                     navigate(generatePath(routes.merge_x_taxonomy_health_check, {
    //                         id: bagId,
    //                         taxonomyId: p.p.hardcodedOriginalTaxonomyId || -1,
    //                     }))
    //                 }}
    //             >
    //                 <LocalHospital/>
    //             </IconButton>
    //         )}
    //         {showAiTaxonomySuggestionButton && (
    //             <IconButton
    //                 color="primary"
    //                 // className="toolbar-btn large-icon-btn"
    //                 onClick={() => {
    //                     navigate(generatePath(routes.merge_x_taxonomy_suggestor, {
    //                         id: bagId,
    //                         taxonomyId: p.p.hardcodedOriginalTaxonomyId || -1,
    //                     }));
    //                 }}
    //             >
    //                 <SmartToy/>
    //             </IconButton>
    //         )}
    //         <IconButton
    //             color="primary"
    //             // className="toolbar-btn large-icon-btn"
    //             onClick={() => taxonomyEditorStore.goUpLevel()}
    //             disabled={!taxonomyEditorStore.canGoUp}
    //         >
    //             <ChevronLeft/>
    //             {/*{taxonomyEditorStore.goBackButtonTxt}*/}
    //         </IconButton>
    //         <IconButton
    //             // className="toolbar-btn large-icon-btn btn-red"
    //             color="primary"
    //             onClick={() => taxonomyEditorStore.deleteSelection()}
    //             disabled={allButtonsDisabled || taxonomyEditorStore.selection.length === 0}
    //             style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
    //         >
    //             <DeleteOutline/>
    //         </IconButton>
    //         <IconButton
    //             // className="toolbar-btn large-icon-btn"
    //             color="primary"
    //             onClick={() => taxonomyEditorStore.setMergeCategoryMode(true)}
    //             disabled={allButtonsDisabled || taxonomyEditorStore.selection.length !== 2}
    //             style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
    //         >
    //             <CallMerge style={{transform: 'rotate(90deg)'}}/>
    //         </IconButton>
    //         <IconButton
    //             // className="toolbar-btn large-icon-btn"
    //             color="primary"
    //             onClick={() => taxonomyEditorStore.setMoveCategoryMode(true)}
    //             disabled={allButtonsDisabled || taxonomyEditorStore.selection.length === 0}
    //             style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
    //         >
    //             <ArrowRightAlt/>
    //         </IconButton>
    //         <IconButton
    //             // className="toolbar-btn large-icon-btn"
    //             color="primary"
    //             onClick={() => taxonomyEditorStore.setUpdateCategoryMode(true)}
    //             disabled={allButtonsDisabled || taxonomyEditorStore.selection.length !== 1}
    //             style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
    //         >
    //             <Description/>
    //         </IconButton>
    //         <IconButton
    //             // className="toolbar-btn large-icon-btn"
    //             color="primary"
    //             onClick={() => taxonomyEditorStore.toggleEditMode()}
    //             disabled={allButtonsDisabled}
    //         >
    //             <Edit/>
    //         </IconButton>
    //
    //         <Popper
    //             // id="taxonomy-editor-button-settings-popper"
    //             open={open}
    //             anchorEl={anchorEl}
    //             placement="bottom"
    //             transition
    //             disablePortal
    //         >
    //             {({TransitionProps, placement}) => (
    //                 <Grow
    //                     {...TransitionProps}
    //                     style={{
    //                         transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
    //                     }}
    //                 >
    //                     <div className="overlay-container">
    //                         <IconButton
    //                             disabled
    //                             className="toolbar-btn large-icon-btn"
    //                             color="primary"
    //                             onClick={handleCloseSettings}
    //                         >
    //                             <Description/>
    //                             <div className="btn-text">
    //                                 View
    //                                 <br/>
    //                                 change log
    //                             </div>
    //                         </IconButton>
    //                         <IconButton
    //                             disabled
    //                             className="toolbar-btn large-icon-btn"
    //                             color="primary"
    //                             onClick={handleCloseSettings}
    //                         >
    //                             <Sync/>
    //                             <div className="btn-text">
    //                                 Calculate
    //                                 <br/>
    //                                 new amounts
    //                             </div>
    //                         </IconButton>
    //                         <IconButton
    //                             disabled
    //                             className="toolbar-btn large-icon-btn"
    //                             color="primary"
    //                             onClick={handleCloseSettings}
    //                         >
    //                             <Check/>
    //                             <div className="btn-text">Submit for approval</div>
    //                         </IconButton>
    //                     </div>
    //                 </Grow>
    //             )}
    //         </Popper>
    //         <IconButton
    //             // className="toolbar-btn large-icon-btn"
    //             color="primary"
    //             onClick={() => {
    //                 const taxonomyId = taxonomyManagerStore.taxonomy?.id;
    //                 if (taxonomyId) {
    //                     setIsDownloadingExcel(true);
    //                     const filename = 'Taxonomy.xlsx';
    //                     materializedApi.downloadTaxonomyExcel(taxonomyId, filename).then((resp) => {
    //                         const url = URL.createObjectURL(new Blob([resp.data]));
    //                         const link = document.createElement('a');
    //                         link.href = url;
    //                         link.setAttribute('download', filename);
    //                         document.body.appendChild(link);
    //                         link.click();
    //                         link.parentNode?.removeChild(link);
    //                         setHasDownloadedExcel(true);
    //                     });
    //                 }
    //             }}
    //             disabled={allButtonsDisabled || isDownloadingExcel}
    //             // style={taxonomyEditorStore.isEditMode ? {display: 'none'} : {minWidth: '10em'}}
    //         >
    //             {/*{isDownloadingExcel ? 'Downloading ...' : 'Download'}*/}
    //             {isDownloadingExcel ? undefined : (hasDownloadedExcel ? <Done/> : <CloudDownload/>)}
    //         </IconButton>
    //         {!p.p.hideApprovalButtons && (
    //             <IconButton
    //                 // className="toolbar-btn large-icon-btn"
    //                 color="primary"
    //                 disabled={
    //                     allButtonsDisabled ||
    //                     !taxonomyManagerStore.stateInSync ||
    //                     !taxonomyManagerStore.undoAllowed ||
    //                     environment.package === 'merge_x'
    //                 }
    //                 style={taxonomyEditorStore.isEditMode ? {display: 'none'} : {}}
    //                 onClick={() => taxonomyManagerStore.setSendForApprovalDialog(true)}
    //             >
    //                 <Approval/>
    //             </IconButton>
    //         )}
    //     </div>
    // );
})
