import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {JobRouteMatch} from "../../routing/routes";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {SingleDatabagWrapped} from "./base/SingleDatabagWrapped";

export const DoubleEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {authStore, rootAppStore, bagLoadingStore} = useStores();
    const params = useParams<JobRouteMatch>();
    const location = useLocation();
    const navigate = useNavigate();

    // TODO: See if this can be optimized, probably by the usage of react Context
    // Beware: This can cause the page to be rendered with the previous state, we have to be resilient against this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the job
    useEffect(() => {
        bagLoadingStore.initBag(location, navigate, params)
        authStore.loadUsers()
        // cmpStore.initBags(location, navigate, match as any)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <SingleDatabagWrapped state={state}>{children}</SingleDatabagWrapped>;
});
