import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Grid,
    LinearProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import {useSitStores} from "../../../stores";
// import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {INITIATIVE_COLUMNS} from "./columns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {generatePath, useNavigate} from "react-router-dom";
import {sitRoutes} from "../../routing/sitRoutes";
import {InitiativeFilters} from "./InitiativeFilters";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import moment from "moment";
import {DoiStatus, traffic_light} from "../../ApiTypes";
import {Circle} from "@mui/icons-material";


export const InitiativeTable: React.FC = observer(() => {
    const {ccStore, initiativeListStore} = useSitStores();
    const navigate = useNavigate();

    useEffect(() => {
        if (!initiativeListStore.initiativeListRM.result && !initiativeListStore.initiativeListRM.busy)
            initiativeListStore.initiativeListRM.request({filters: initiativeListStore.initiativeListFilter.filters});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Grid container className="initiative-table-component">
        <Grid item xs={12}>
            <InitiativeFilters/>
        </Grid>
        <Grid item xs={12}>
            <TableContainer className={'mithra-table x-design initiative-table' +
                (initiativeListStore.initiativeListRM.busy ? ' loading' : '')}>
                <Table>
                    {/*<MithraTableHeadColumns*/}
                    {/*    columns={INITIATIVE_COLUMNS} enableLoading*/}
                    {/*    isLoading={initiativeListStore.initiativeListRM.busy}/>*/}
                    <TableHead>
                        <TableRow>
                            {INITIATIVE_COLUMNS.map((c, i) => {
                                const currentOrder = initiativeListStore.initiativeListFilter.filters.find(
                                    (item) => item[0] === 'ordering'
                                )?.at(1) ?? '';
                                const asc = !currentOrder.startsWith('-');
                                return <TableCell
                                    key={i}
                                    className={c.cls + ' sortable'}
                                    align={c.align}
                                    style={{width: c.width, minWidth: c.minWidth}}>
                                    <TableSortLabel
                                        onClick={() =>
                                            initiativeListStore.initiativeListFilter.setFilters([['ordering', (asc ? '-' : '') + c.columnFieldId]])
                                        }
                                        active={asc ? (c.columnFieldId === currentOrder) : (('-' + c.columnFieldId) === currentOrder)}
                                        direction={asc ? 'asc' : 'desc'}>
                                        {c.txt}
                                    </TableSortLabel>
                                </TableCell>
                            })}
                        </TableRow>
                        <tr className={'progress-row' + (initiativeListStore.initiativeListRM.busy ? ' loading' : '')}>
                            <td colSpan={INITIATIVE_COLUMNS.length}>
                                {initiativeListStore.initiativeListRM.busy && <LinearProgress variant="indeterminate"/>}
                            </td>
                        </tr>
                    </TableHead>
                    {initiativeListStore.initiativeListRM.result &&
                        <TableBody>
                            {initiativeListStore.initiativeListRM.result?.results.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[0]}>
                                            <Link onClick={() => {
                                                navigate(generatePath(sitRoutes.sit_initiative, {initiativeId: row.id}))
                                            }}>
                                                <div style={{width: '100%'}}>
                                                    {row.title}
                                                </div>
                                            </Link>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[1]}>
                                            {row.owner ? row.owner.name : 'No owner'}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[2]}>{
                                            ccStore.getCategoryL1(row.financial?.at(0)?.categories?.at(0) ?? 0)
                                        }
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[3]}>
                                            {ccStore.getLeverGroupTitle(row.lever_id)}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[4]}>
                                            <Circle sx={{color: traffic_light(row)[0]}}/>&nbsp;
                                            <Typography variant="caption" component="small">
                                                {traffic_light(row)[1]}
                                            </Typography>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[5]}>
                                            {row.doi_status + ': ' + DoiStatus[row.doi_status - 1]}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[6]}>
                                            <CurrencyComponent v={row.financial?.at(0)?.saving_amount ?? 0}
                                                               nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[7]}>
                                            <CurrencyComponent v={row.financial?.at(0)?.avoidance_amount ?? 0}
                                                               nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[8]}>
                                            {row.updated ? moment(row.updated).format('L') : ''}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[9]}>
                                            {row.responsible.name}
                                        </MithraTableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={INITIATIVE_COLUMNS.length}
                                count={initiativeListStore.initiativeListRM.result?.count ?? 0}
                                rowsPerPage={parseInt(initiativeListStore.initiativeListFilter.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                                page={parseInt(initiativeListStore.initiativeListFilter.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                                onPageChange={(event, page: number) => {
                                    initiativeListStore.initiativeListFilter.setFilters([['page', (page + 1).toString()]]);
                                }}
                                onRowsPerPageChange={(event) => {
                                    initiativeListStore.initiativeListFilter.setFilters([['page_size', (event.target.value).toString()]], false);
                                    initiativeListStore.initiativeListFilter.setFilters([['page', '1']]);
                                }}
                                ActionsComponent={MithraTablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
});