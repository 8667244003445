import React from "react";
import {observer} from "mobx-react-lite";
import {Autocomplete, FormControl, TextField} from "@mui/material";
import {SimpleSitUserProfile, SitUserProfile} from "../ApiTypes";
import {useSitStores} from "../../stores";
import {runInAction} from "mobx";

interface Props<T> {
    label: string,
    placeholder: string,
    value: T | undefined | null,
    onChange: (event: React.SyntheticEvent, value: T | null) => void,
    required?: boolean,
}

export const SitUserSelect = observer(
    <T extends SitUserProfile | SimpleSitUserProfile, >({label, placeholder, value, onChange, required}: Props<T>) => {
        const {sitUserStore, initiativeInstanceStore} = useSitStores();

        return <FormControl fullWidth margin="dense">
            <Autocomplete
                disabled={initiativeInstanceStore.disabled}
                selectOnFocus clearOnBlur handleHomeEndKeys
                options={(sitUserStore.userListRM.result?.results ?? []) as T[]}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterOptions={(options) => options}
                value={value ?? null}
                renderOption={(props, option, {selected}) =>
                    <li key={option.id} {...props}>
                        {option.name + ' <' + option.email + '>'}
                    </li>}
                onChange={onChange}
                onOpen={() => {
                    if (sitUserStore.userListRM.result === undefined)
                        sitUserStore.userListFilter.setFilters([['search', '']]);
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        required={required}
                        placeholder={placeholder}/>}
                onInputChange={(_, value, reason) => {
                    switch (reason) {
                        case 'input':
                            runInAction(() => {
                                sitUserStore.userListFilter.setFilters([['search', value]])
                            });
                            break;
                        case 'clear':
                            runInAction(() => {
                                sitUserStore.userListFilter.setFilters([['search', '']])
                            });
                            break;
                    }
                }}/>
        </FormControl>
    }
)
