import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useSitStores} from "../../../../stores";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {KoiFacade} from "../../../../components/koi-facade/KoiFacade";
import {InitiativeFinancialForm} from "./initiativeFinancialForm";
import {sitRoutes} from "../../../routing/sitRoutes";
import {InitiativePlaningFrom} from "./InitiativePlaningFrom";
import {InitiativeSuccessStepFrom} from "./InitiativeSuccessStepFrom";
import {Add, Check, UploadFile} from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {InitiativeScopeForm} from "./initiativeScopeForm";
import {InitiativeGeneralForm} from "./initiativeGeneralForm";
import {InitiativeSkuForm} from "./initiativeSkuForm";
import {InitiativePeopleForm} from "./initiativePeopleForm";
import {InitiativeAttachmentForm} from "./initiativeAttachmentForm";
import {styled} from "@mui/material/styles";
// import {InitiativeTeamForm} from "./initiativeTeamForm";

// See https://mui.com/material-ui/react-button/#file-upload
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const InitiativeForm: React.FC = observer(() => {
    const {initiativeListStore, initiativeInstanceStore, attachmentStore, sitApi} = useSitStores();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const initiativeId: number | 'new' = 'initiativeId' in params ? parseInt(params['initiativeId'] ?? '') || 'new' : 'new';
    const [isLoading, setIsLoading] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isLoadingApproval, setIsLoadingApproval] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    useEffect(() => {
        if (initiativeId === 'new')
            initiativeInstanceStore.setInstance({});
        else
            sitApi.getInitiative(initiativeId).then((resp) => {
                initiativeInstanceStore.setInstance(resp.data);
            }).catch((_reason) => {
                navigate(generatePath(sitRoutes.sit_initiatives));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initiativeId]);

    return <KoiFacade
        title={<>
            <Typography variant="h1" component="h1">
                {initiativeId === 'new' ?
                    'Add New Initiative' : 'Initiative details'}
                {initiativeId !== 'new' &&
                    <Chip label={'ID: ' + initiativeInstanceStore.instance.id?.toString()} variant="outlined"
                          className="ml-2 mr-2"/>}
                {initiativeInstanceStore.instance.approved &&
                    <Chip label={'Approved'} variant="outlined" color="success"/>}
            </Typography>
        </>}
        onBack={() => {
            // Release possible lock
            if (!initiativeInstanceStore.disabled)
                initiativeInstanceStore.releaseLock();
            initiativeInstanceStore.setInstance({});
            navigate(generatePath(sitRoutes.sit_initiatives));
        }}
        backClass={""}
        next={initiativeId !== 'new' ? {
            type: 'component',
            node: <Tooltip
                title={!initiativeInstanceStore.instance.locked_by ? '' : 'Locked by ' + initiativeInstanceStore.instance.locked_by.name}>
                <span>
                    <Button
                        disabled={
                            initiativeInstanceStore.disabled &&
                            (initiativeInstanceStore.instance.locked_by != null) &&
                            (initiativeInstanceStore.instance.locked_by!.id !== initiativeInstanceStore.authStore.sitUserProfile?.id)
                        }
                        variant="outlined"
                        color="warning"
                        startIcon={initiativeInstanceStore.disabled ? <LockIcon/> : <LockOpenIcon/>}
                        onClick={() => {
                            if (initiativeInstanceStore.disabled)
                                initiativeInstanceStore.getLock();
                            else
                                initiativeInstanceStore.releaseLock();
                        }}>
                        {initiativeInstanceStore.disabled ? 'Locked' : 'Unlocked'}
                    </Button>
                </span>
            </Tooltip>
        } : {
            type: 'component', node: <></>
        }}
        nextClass=""
        className="initiative-page">
        <form onSubmit={(event) => {
            event.preventDefault();
            setIsLoading(true);
            initiativeInstanceStore.createOrUpdate()
                .then(() => {
                    setErrorMessage('')
                    initiativeInstanceStore.setInstance({});
                    initiativeListStore.initiativeListFilter.filters = initiativeListStore.initiativeListFilter.initFilters;
                    initiativeListStore.initiativeListRM.request({filters: initiativeListStore.initiativeListFilter.filters});
                    navigate(generatePath(sitRoutes.sit_initiatives));
                })
                .catch(error => {
                    console.error(error)
                    setErrorMessage('Could not add initiative')
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">General Information</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativeGeneralForm/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Scope</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativeScopeForm/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="h3" component="h3">Related SKUs</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3} className="mt-2" justifyContent="end">
                                <Grid item>
                                    <Button
                                        disabled={initiativeInstanceStore.disabled}
                                        variant="outlined"
                                        startIcon={<Add/>}
                                        onClick={() => initiativeInstanceStore.addSku()}>
                                        ADD SKU NUMBER
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativeSkuForm/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">People & Roles</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativePeopleForm/>
                        </Grid>
                    </Grid>

                    {/*<Grid container spacing={3}>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <Typography variant="h3" component="h3">Team Members</Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <InitiativeTeamForm/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Financial Information</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativeFinancialForm/>
                        </Grid>
                    </Grid>

                    {!initiativeInstanceStore.creating && <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="h3" component="h3">Attachments</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3} className="mt-2" justifyContent="end">
                                <Grid item>
                                    {initiativeInstanceStore.instance.id && initiativeInstanceStore.instance.id > 0 &&
                                        <Button
                                            disabled={initiativeInstanceStore.disabled || isUploading}
                                            variant="outlined"
                                            component="label"
                                            role={undefined}
                                            startIcon={<UploadFile/>}>
                                            Upload Document
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={(event) => {
                                                    if (event.target.files && event.target.files.item(0) !== null) {
                                                        setIsUploading(true);
                                                        sitApi.createInitiativeAttachment(
                                                            initiativeInstanceStore.instance.id!, event.target.files.item(0)!
                                                        ).then(() => {
                                                            attachmentStore.initiativeAttachmentsRM.request({id: initiativeInstanceStore.instance.id!});
                                                            setIsUploading(false);
                                                        });
                                                    }
                                                }}/>
                                        </Button>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativeAttachmentForm/>
                        </Grid>
                    </Grid>}

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Project Planing</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativePlaningFrom/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Success Steps</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InitiativeSuccessStepFrom/>
                        </Grid>
                    </Grid>

                    {/*<Grid container spacing={3}>*/}
                    {/*    <Grid item xs={6}>*/}
                    {/*        <Typography variant="h3" component="h3">KPI For Monitoring</Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={6}>*/}
                    {/*        <Grid container spacing={3} className="mt-2" justifyContent="end">*/}
                    {/*            <Grid item>*/}
                    {/*                <Button*/}
                    {/*                    disabled={initiativeInstanceStore.disabled}*/}
                    {/*                    variant="outlined"*/}
                    {/*                    startIcon={<Add/>}*/}
                    {/*                    onClick={() => initiativeInstanceStore.kpiDialogOpen = true}>*/}
                    {/*                    New KPI for monitoring*/}
                    {/*                </Button>*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <InitiativeKpiForm/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    {initiativeInstanceStore.readyForApproval && <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled
                                    multiline minRows={3}
                                    variant="outlined"
                                    label="Approval Notes"
                                    value={initiativeInstanceStore.description.instance?.approval_notes ?? ''}/>
                            </FormControl>
                        </Grid>
                    </Grid>}

                    <Grid container spacing={3} className="mt-2" justifyContent="end">
                        {!initiativeInstanceStore.disabled && !initiativeInstanceStore.readyForApproval &&
                            <Grid item>
                                <Button
                                    disabled={initiativeInstanceStore.disabled}
                                    className="mr-4"
                                    onClick={() => {
                                        // Release possible lock
                                        if (!initiativeInstanceStore.disabled)
                                            initiativeInstanceStore.releaseLock();
                                        // Clear form data
                                        initiativeInstanceStore.setInstance({});
                                        navigate(generatePath(sitRoutes.sit_initiatives));
                                    }}>
                                    Cancel
                                </Button>

                                <Button
                                    disabled={isLoading || initiativeInstanceStore.disabled}
                                    className="loading-button"
                                    type="submit"
                                    startIcon={isLoading ? <CircularProgress size={14}/> : <Check/>}
                                    variant="contained">
                                    {initiativeId === 'new' ? 'Add initiative' : 'Update initiative'}
                                </Button>
                            </Grid>
                        }

                        {initiativeInstanceStore.readyForApproval &&
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className="bg-danger mr-4"
                                    onClick={() => {
                                        initiativeInstanceStore.approve(false)?.then(() => {
                                            navigate(generatePath(sitRoutes.sit_initiatives));
                                        });
                                    }}>
                                    Reject
                                </Button>

                                <Button
                                    variant="contained"
                                    className="bg-primary"
                                    onClick={() => {
                                        initiativeInstanceStore.approve(true)?.then(() => {
                                            navigate(generatePath(sitRoutes.sit_initiatives));
                                        });
                                    }}>
                                    Accept
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={3} className="mt-2" justifyContent="end">
                        <Grid item>
                            {errorMessage &&
                                <Alert severity="error">
                                    {errorMessage}
                                </Alert>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={initiativeInstanceStore.approval_dialog_open} aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                onClose={() => initiativeInstanceStore.approvalDialogOpen = false}>
                <DialogTitle id="dialog-title">
                    Approval Required
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Changing initiative status to
                        <b> "DOI5 - EBIT effective" </b>
                        requires approval from
                        <b> "{initiativeInstanceStore.instance.approver?.name}" </b>.
                        <br/>
                        Are you sure you want to submit this change?
                    </DialogContentText>
                    <Grid container className="mt-2">
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    multiline minRows={3}
                                    variant="outlined"
                                    label="Approval Notes"
                                    value={initiativeInstanceStore.description.instance?.approval_notes ?? ''}
                                    onChange={(event) =>
                                        initiativeInstanceStore.description.approval_notes = event.target.value
                                    }/>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => initiativeInstanceStore.approvalDialogOpen = false}>cancel</Button>
                    <Button
                        variant="contained"
                        disabled={isLoadingApproval}
                        className="loading-button"
                        onClick={() => {
                            setIsLoadingApproval(true);
                            initiativeInstanceStore.createOrUpdate().finally(() => {
                                setIsLoadingApproval(false);
                                initiativeInstanceStore.approvalDialogOpen = false;
                                navigate(generatePath(sitRoutes.sit_initiatives));
                            });
                        }}>
                        Send for approval
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    </KoiFacade>
});