import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useSitStores} from "../../../stores";
import {generatePath, useNavigate} from "react-router-dom";
import {
    Chip,
    Grid,
    Link,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow
} from "@mui/material";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {INITIATIVE_APPROVAL_COLUMNS} from "./columns";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {sitRoutes} from "../../routing/sitRoutes";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import moment from "moment/moment";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {Initiative} from "../../ApiTypes";

export const InitiativeApprovalDashboard: React.FC = observer(() => {
    const {ccStore, initiativeListStore} = useSitStores();
    const navigate = useNavigate();

    useEffect(() => {
        if (!initiativeListStore.initiativeApprovalListRM.result && !initiativeListStore.initiativeApprovalListRM.busy)
            initiativeListStore.initiativeApprovalListRM.request({filters: initiativeListStore.initiativeListFilter.filters});
    }, []);

    return <Grid container className="initiative-approval-table-component">
        {/*<Grid item xs={12}>*/}
        {/*    <InitiativeFilters/>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
            <TableContainer className={'mithra-table x-design initiative-approval-table' +
                (initiativeListStore.initiativeApprovalListRM.busy ? ' loading' : '')}>
                <Table>
                    <MithraTableHeadColumns columns={INITIATIVE_APPROVAL_COLUMNS} enableLoading
                                            isLoading={initiativeListStore.initiativeApprovalListRM.busy}/>
                    {initiativeListStore.initiativeApprovalListRM.result &&
                        <TableBody>
                            {initiativeListStore.initiativeApprovalListRM.result?.results.map((row: Initiative) => {
                                return (
                                    <TableRow key={row.id}>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[0]}>
                                            <Link onClick={() => {
                                                navigate(generatePath(sitRoutes.sit_initiative, {initiativeId: row.id}))
                                            }}>
                                                <div style={{width: '100%'}}>
                                                    {row.title}
                                                </div>
                                            </Link>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[1]}>
                                            {row.owner ? row.owner.email : 'No owner'}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[2]}>
                                            {
                                                row.financial.length > 0 &&
                                                ccStore.getCategoryL1(row.financial[0].categories?.at(0) ?? 0)
                                            }
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[3]}>
                                            {ccStore.getLeverGroupTitle(row.lever_id)}
                                        </MithraTableCell>
                                        {/*<MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[4]}>{*/}
                                        {/*    // TODO CAT-2025*/}
                                        {/*    <Circle />*/}
                                        {/*}*/}
                                        {/*</MithraTableCell>*/}
                                        <MithraTableCell
                                            c={INITIATIVE_APPROVAL_COLUMNS[4]}>{row.doi_status}</MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[5]}>
                                            <CurrencyComponent v={row.financial[0].saving_amount * 1000} nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[6]}>
                                            <CurrencyComponent v={row.financial[0].avoidance_amount * 1000}
                                                               nDigits={3}/>
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[7]}>
                                            {row.approver ? row.approver.email : 'No approver'}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[8]}>
                                            {row.approved ? <Chip label="Approved" color="primary" style={{
                                                backgroundColor: '#08A57A',
                                                width: '100%'
                                            }}/> : <Chip label="Pending" color="secondary"
                                                         style={{backgroundColor: '#FAA915', width: '100%'}}/>}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[9]}>
                                            {row.created ? moment(row.created).format('L') : ''}
                                        </MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_APPROVAL_COLUMNS[10]}>
                                            {row.responsible.email}
                                        </MithraTableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={INITIATIVE_APPROVAL_COLUMNS.length}
                                count={initiativeListStore.initiativeApprovalListRM.result?.count ?? 0}
                                rowsPerPage={parseInt(initiativeListStore.initiativeListFilter.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                                page={parseInt(initiativeListStore.initiativeListFilter.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                                onPageChange={(event, page: number) => {
                                    initiativeListStore.initiativeListFilter.setFilters([['page', (page + 1).toString()]]);
                                }}
                                onRowsPerPageChange={(event) => {
                                    initiativeListStore.initiativeListFilter.setFilters([['page_size', (event.target.value).toString()]], false);
                                    initiativeListStore.initiativeListFilter.setFilters([['page', '1']]);
                                }}
                                ActionsComponent={MithraTablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
});