import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, Link, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow,} from "@mui/material";
import {useSitStores} from "../../../stores";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {TARGET_COLUMNS} from "./columns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {TargetFilters} from "./targetFilters";
import {generatePath, useNavigate} from "react-router-dom";
import {sitRoutes} from "../../routing/sitRoutes";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import moment from "moment/moment";

export const TargetTable: React.FC = observer(() => {
    const {targetListStore, ccStore} = useSitStores();
    const navigate = useNavigate();

    useEffect(() => {
        if (!targetListStore.targetListRM.result && !targetListStore.targetListRM.busy)
            targetListStore.targetListRM.request({filters: targetListStore.targetListFilter.filters});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Grid container className="target-table-component">
        <Grid item xs={12}>
            <TargetFilters/>
        </Grid>
        <Grid item xs={12}>
            <TableContainer className={'mithra-table x-design target-table' +
                (targetListStore.targetListRM.busy ? ' loading' : '')}>
                <Table>
                    <MithraTableHeadColumns columns={TARGET_COLUMNS} enableLoading
                                            isLoading={targetListStore.targetListRM.busy}/>
                    {targetListStore.targetListRM.result &&
                        <TableBody>
                            {targetListStore.targetListRM.result?.results.map((row) => {
                                let i = 0;
                                return (
                                    <TableRow key={row.id}>
                                        <MithraTableCell c={TARGET_COLUMNS[i++]}>
                                            <Link onClick={() => {
                                                navigate(generatePath(sitRoutes.sit_target, {targetId: row.id}))
                                            }}>
                                                <div style={{width: '100%'}}>
                                                    {row.title}
                                                </div>
                                            </Link>
                                        </MithraTableCell>
                                        <MithraTableCell c={TARGET_COLUMNS[i++]}>
                                            {row.owner ? row.owner.name : 'No owner'}
                                        </MithraTableCell>
                                        <MithraTableCell c={TARGET_COLUMNS[i++]}>{
                                            // Filter here is to make array values unique
                                            row.categories.map((id) => ccStore.getCategoryL1(id)).filter(
                                                (value, index, array) => {
                                                    return array.indexOf(value) === index;
                                                }).join(', ')
                                        }</MithraTableCell>
                                        {/*<MithraTableCell*/}
                                        {/*    c={TARGET_COLUMNS[i++]}>{row.lever?.group.title}</MithraTableCell>*/}
                                        <MithraTableCell c={TARGET_COLUMNS[i++]}>{
                                            row.companies.map((id) => ccStore.getCompanyTitle(id)).join(', ')
                                        }</MithraTableCell>
                                        <MithraTableCell c={TARGET_COLUMNS[i++]}>
                                            {row.start_date ? moment(row.start_date).format('L') : ''}
                                        </MithraTableCell>
                                        <MithraTableCell c={TARGET_COLUMNS[i++]}>{row.target_year}</MithraTableCell>
                                        <MithraTableCell c={TARGET_COLUMNS[i++]}>
                                            <CurrencyComponent v={row.saving_amount * 1000} nDigits={3}/>
                                        </MithraTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={TARGET_COLUMNS.length}
                                count={targetListStore.targetListRM.result?.count ?? 0}
                                rowsPerPage={parseInt(targetListStore.targetListFilter.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                                page={parseInt(targetListStore.targetListFilter.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                                onPageChange={(event, page: number) => {
                                    targetListStore.targetListFilter.setFilters([['page', (page + 1).toString()]]);
                                }}
                                onRowsPerPageChange={(event) => {
                                    targetListStore.targetListFilter.setFilters([['page_size', (event.target.value).toString()]], false);
                                    targetListStore.targetListFilter.setFilters([['page', '1']]);
                                }}
                                ActionsComponent={MithraTablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
});