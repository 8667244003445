import React from "react";
import {observer} from "mobx-react-lite";
import {
    Checkbox,
    FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography
} from "@mui/material";
import {useSitStores} from "../../../../stores";

export const InitiativeGeneralForm: React.FC = observer(() => {
    const {ccStore, initiativeInstanceStore} = useSitStores();

    return <Grid container spacing={3}>
        <Grid item xs={6}>
            <FormControl fullWidth>
                <TextField
                    required
                    disabled={initiativeInstanceStore.disabled}
                    variant="standard"
                    label="Title"
                    placeholder="Enter Title"
                    value={initiativeInstanceStore.instance.title ?? ''}
                    onChange={(event) =>
                        initiativeInstanceStore.title = event.target.value
                    }/>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
            <FormControl fullWidth>
                <TextField
                    disabled={initiativeInstanceStore.disabled}
                    multiline minRows={3}
                    variant="outlined"
                    label="Initiative Description"
                    placeholder="Initiative Description"
                    value={initiativeInstanceStore.description.instance?.description ?? ''}
                    onChange={(event) =>
                        initiativeInstanceStore.description.description = event.target.value
                    }/>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
            <FormControl fullWidth>
                <TextField
                    disabled={initiativeInstanceStore.disabled}
                    multiline minRows={3}
                    variant="outlined"
                    label="Confidential Description"
                    placeholder="Confidential Description"
                    value={initiativeInstanceStore.description.instance?.confidential_description ?? ''}
                    onChange={(event) =>
                        initiativeInstanceStore.description.confidential_description = event.target.value
                    }/>
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FormControl fullWidth>
                <InputLabel id="lever-group-label">Lever group</InputLabel>
                <Select
                    disabled={initiativeInstanceStore.disabled}
                    labelId="lever-group-label"
                    id="lever-group"
                    value={initiativeInstanceStore.lever_group_id ?? ''}
                    label="Lever group"
                    onChange={(event) => {
                        if (typeof event.target.value === 'number')
                            initiativeInstanceStore.leverGroupId = event.target.value;
                    }}>
                    {ccStore.leverGroupRM.result?.map((lever_group) => {
                        return <MenuItem
                            key={lever_group.id}
                            value={lever_group.id}>{lever_group.title}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FormControl required fullWidth>
                <InputLabel id="lever-label">Lever</InputLabel>
                <Select
                    disabled={initiativeInstanceStore.disabled}
                    labelId="lever-label"
                    id="lever"
                    value={initiativeInstanceStore.instance.lever_id ?? ''}
                    label="Lever"
                    onChange={(event) => {
                        if (typeof event.target.value === 'number')
                            initiativeInstanceStore.leverId = event.target.value;
                    }}>
                    {initiativeInstanceStore.leverChoices.map((lever) => {
                        return <MenuItem
                            key={lever.id}
                            value={lever.id}>{lever.title}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                variant="standard"
                disabled
                multiline
                label="Lever Description"
                value={initiativeInstanceStore.leverDescription}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="caption" component="p" color="gray">Confidentiality Options</Typography>
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel control={
                <Checkbox
                    disabled={initiativeInstanceStore.disabled}
                    value={initiativeInstanceStore.instance.confidential}
                    onChange={(event, checked) =>
                        initiativeInstanceStore.confidential = checked
                    }/>
            } label="Confidential"/>
            <FormControlLabel control={
                <Checkbox
                    disabled={initiativeInstanceStore.disabled}
                    value={initiativeInstanceStore.instance.nda_required}
                    onChange={(event, checked) =>
                        initiativeInstanceStore.nda_required = checked
                    }/>
            } label="Under NDA"/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="caption" component="p" color="gray">Special Project Options</Typography>
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel control={
                <Checkbox
                    disabled={initiativeInstanceStore.disabled}
                    value={initiativeInstanceStore.instance.sustainability}
                    onChange={(event, checked) =>
                        initiativeInstanceStore.sustainability = checked
                    }/>
            } label="Sustainabiliy"/>
            <FormControlLabel control={
                <Checkbox
                    disabled={initiativeInstanceStore.disabled}
                    value={initiativeInstanceStore.instance.risk_reduction}
                    onChange={(event, checked) =>
                        initiativeInstanceStore.riskReduction = checked
                    }/>
            } label="Rist Reduction"/>
            <FormControlLabel control={
                <Checkbox
                    disabled={initiativeInstanceStore.disabled}
                    value={initiativeInstanceStore.instance.compliance}
                    onChange={(event, checked) =>
                        initiativeInstanceStore.compliance = checked
                    }/>
            } label="Compliance"/>
        </Grid>
    </Grid>
});