import {DashboardTypeItem, DashboardTypeMenu} from "../../../components/dashboard/DashboardTypeMenu";
import {CmpDataBagRouteMatch, JobRouteMatch, routes} from "../../../routing/routes";
import {generatePath} from "react-router";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import {generateDashboardPath} from "../../../routing/routing.utils";
import {getDashboardEnv, MergeXCleanupEnvironments} from "./constants";
import {FormControl, ListSubheader, MenuItem, Typography} from "@mui/material";
import Select from "@mui/material/Select";
import {SelectDataset} from "../../../components/main/header/SelectDataset";
import {environment} from "../../../env";

type Item = DashboardTypeItem<MergeXCleanupEnvironments> & {}
const ITEMS: Item[] = [
    {key: 'cleanup', label: 'Cleanup',},
    {key: 'synergy', label: 'Combined',},
]

export const MergeXCleanupDashboardSwitcher: React.FC = observer(() => {
    const {p, rootAppStore, bagStore} = useStores();
    const params = useParams<CmpDataBagRouteMatch | JobRouteMatch | {}>();
    const location = useLocation();
    const navigate = useNavigate();
    // const isHome = location.pathname === lookupHomePath(location, rootAppStore.app);
    const isHome = location.pathname === routes.home;
    const selectedKey = getDashboardEnv(rootAppStore.app);
    const doNotShowDataset = getDashboardEnv(rootAppStore.app) === 'synergy';

    const onChange = (env: MergeXCleanupEnvironments) => {
        rootAppStore.setDashboardEnvironment(env)
        const fallbackBagId = bagStore.userBagDefaultOption?.id;

        if ('id2' in params || 'id' in params) {
            if (env === 'cleanup') {
                // Move to cleanup

                let bagId: number = Number(params.id || fallbackBagId);
                if (bagId === p.p.hardcodedCombinedBagId) {
                    bagId = Number(fallbackBagId);
                }
                console.log('MergeXC MergeXCleanupDashboardSwitcher -> cleanup:', bagId)
                bagStore.changeBag(bagId, navigate, location);
                return;
            }

            // If some ID is present, keep the selection in the URL
            console.log('MergeXC MergeXCleanupDashboardSwitcher -> synergy:', params)
            navigate(generateDashboardPath(location, params, rootAppStore.app));
            return;
        }

        if (!fallbackBagId) {
            console.warn('MergeXCleanupDashboardSwitcher: Cannot determine which bag to select for the dashboard, cannot switch dashboard')
            return;
        }

        console.log('MergeXC MergeXCleanupDashboardSwitcher -> fallback:', fallbackBagId)
        const _params = {id: String(fallbackBagId)};
        navigate(generatePath(routes.merge_xc_combined_dashboard, _params));
    }
    const onSelectDataset = (bagId: number) => {
        console.log('MergeXC MergeXCleanupDashboardSwitcher -> onSelectDataset:', bagId)

        // bagStore.changeBag(bagId, history, routeMatch); ?

        const params = {id: String(bagId)};
        navigate(generatePath(routes.merge_xc_single_dashboard, params));
    }

    if (rootAppStore.app?.dataType === 'no_data' || isHome) {
        return <>
            {/*noData: {'' + (rootAppStore.app?.dataType === 'no_data')}&nbsp;*/}
            {/*isHome: {'' + isHome}*/}
            <FormControl sx={{m: 1, minWidth: 80}} variant="outlined">
                <Select
                    displayEmpty
                    autoWidth
                    renderValue={value => !value ? <em>Select dataset</em> : value}
                    defaultValue=""
                    onChange={value => {
                        if (!value.target.value) {
                            return;
                        }
                        if (value.target.value === 'synergy') {
                            onChange('synergy')
                        } else {
                            const databagId = Number(value.target.value);
                            if (Number.isInteger(databagId)) {
                                onSelectDataset(databagId);
                            }
                        }
                    }}>
                    {ITEMS.slice(1).map(item =>
                        <MenuItem
                            key={item.key}
                            value={item.key}
                        >
                            <Typography variant="inherit">{item.label}</Typography>
                        </MenuItem>
                    )}
                    <ListSubheader>Datasets</ListSubheader>
                    {bagStore.userBagChoices?.map(bag =>
                        <MenuItem key={bag.id} value={bag.id}>
                            {environment.production
                                ? bag.name
                                : <>{bag.hidden ? '(H) ' : ''}[{bag.id}] {bag.name}</>
                            }
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </>
    }
    return <>
        {/*else*/}
        <SelectDataset showEmpty={doNotShowDataset}/>
        <DashboardTypeMenu
            items={ITEMS}
            selectedKey={selectedKey}
            onSelect={(key) => {
                onChange(key as MergeXCleanupEnvironments)
            }}
        />
    </>
})
