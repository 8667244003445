import './TaxonomyEditor.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, TextField} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {JobRouteMatch, TaxonomyRouteMatch} from "../../routing/routes";
import {generateHomePath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {IS_TE_DEVELOPMENT_MODE} from "../../components/visualization/taxonomy-editor/TaxonomyEditorOptions";
import {TaxonomyEditorComponent} from "./TaxonomyEditorComponent";
import {TaxonomyEditorApprovalComponent} from "./approval/TaxonomyEditorApprovalComponent";
import {ChevronRight} from "@mui/icons-material";
import {TaxonomyApprovalComponent} from "../approval/taxonomy/TaxonomyApprovalComponent";

type Props = {
    viewer?: boolean
}
export const TaxonomyEditorPage: React.FC<Props> = observer(({viewer}) => {
    const {rootAppStore, bagStore, taxonomyEditorStore, taxonomyManagerStore, p} = useStores();
    const navigate = useNavigate();
    const params = useParams<JobRouteMatch | TaxonomyRouteMatch>();
    const location = useLocation();
    if (!bagStore.bagId) throw new Error();

    // Trigger a desired taxonomy Id change here
    const urlTaxonomyId = 'taxonomyId' in params ? Number(params['taxonomyId']) : undefined;
    useEffect(() => {
        if (p.p.hardcodeTaxonomyId) {
            // If we have a hardcodeTaxonomyId set, always load the hardcoded one
            taxonomyManagerStore.setDesiredTaxonomyId(p.p.hardcodeTaxonomyId);
        } else if (urlTaxonomyId) {
            // Otherwise load the taxonomy from the URL
            taxonomyManagerStore.setDesiredTaxonomyId(urlTaxonomyId);
        } else {
            // Otherwise do not load the taxonomy store (and assume it's loaded somewhere else in the system)
        }
    }, [taxonomyManagerStore, urlTaxonomyId, p])

    useEffect(() => {
        taxonomyManagerStore.requestTaxonomyList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: Disable edit mode for the taxonomy editor when navigation happens
    //  >>> OK

    const subTitleEditComponent = taxonomyEditorStore.isEditMode
        ? <TextField
            id="taxonomy-title"
            fullWidth
            className="taxonomy-title-edit-field"
            label="Taxonomy name"
            variant="standard"
            value={taxonomyManagerStore.taxonomy?.name || ''}
            onChange={event => {
                taxonomyManagerStore.setTaxonomyName(event.target.value)
            }}
            disabled={taxonomyManagerStore._updateTaxonomyRequest.busy}
            onBlur={() => taxonomyManagerStore.storeTaxonomyName()}
        />
        : taxonomyManagerStore.taxonomy?.name;

    const className = 'taxonomy-editor-page dashboard-main-page'
        + (viewer ? ' viewer' : '')
        + (IS_TE_DEVELOPMENT_MODE ? ' debug' : '')

    let title: string
    let onBack: () => void
    let next: any | undefined = undefined;
    switch (taxonomyEditorStore.page) {
        case 'editor':
            title = viewer ? 'Taxonomy Viewer' : 'Taxonomy Builder'
            onBack = () => {
                navigate(generateHomePath(location, {id: String(bagStore.bagId)}, rootAppStore.app));
            }
            break
        case 'approval':
            title = 'Approval'
            onBack = () => {
                taxonomyEditorStore.approval.saveNotesToTaxonomy()?.then(() => {
                    taxonomyEditorStore.setPage('editor')
                })
            };
            next = {
                type: 'component',
                node: <Button
                    color="primary"
                    variant="outlined"
                    endIcon={<ChevronRight/>}
                    onClick={() => {
                        taxonomyEditorStore.approval.setConfirmModalOpen(true)
                        taxonomyEditorStore.approval.saveNotesToTaxonomy()
                    }}
                    disabled={taxonomyEditorStore.approval.isBusy}>
                    Request new baseline
                </Button>
            }
            break
        case 'approval-feedback':
            title = 'Approval Feedback'
            onBack = () => {
                taxonomyEditorStore.setPage('editor')
            };
            break
    }

    return <KoiFacade
        title={title}
        subTitle={subTitleEditComponent}
        onBack={onBack}
        className={className}
        next={next}
    >
        {taxonomyEditorStore.page === 'editor' && <TaxonomyEditorComponent viewer={viewer}/>}
        {taxonomyEditorStore.page === 'approval' && <TaxonomyEditorApprovalComponent/>}
        {taxonomyEditorStore.page === 'approval-feedback' && <TaxonomyApprovalComponent/>}
    </KoiFacade>
});
