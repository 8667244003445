import React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Typography} from "@mui/material";
import {FinancialFilters} from "../financial-dashboard/financialFilters";
import {BeforeAfterChart} from "./BeforeAfterChart";
import PipelineDashboardController from "./PipelineDashboardController";

export const PipelineDashboard: React.FC = observer(() => {
    const C = PipelineDashboardController;
    return <div className="pipeline-dashboard-page">
        <FinancialFilters/>

        <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h3" component="h3" className="dashboard-title">
                            PPV Savings
                        </Typography>
                        <BeforeAfterChart data={C.ppvSavings} width={500}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h3" component="h3" className="dashboard-title">
                            Non ppv savings
                        </Typography>
                        <BeforeAfterChart data={C.nonPpvSavings} width={500}/>
                    </Grid>
                </Grid>
                {/*<Grid container>*/}
                {/*    data={JSON.stringify(C.contributionPerClusterAvoidance)}*/}
                {/*</Grid>*/}
                {/*<Grid container>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <Typography variant="h3" component="h3" className="dashboard-title">*/}
                {/*            Home Solutions*/}
                {/*        </Typography>*/}
                {/*        <BeforeAfterChart data={C.getContributionsPerClusterCmp(1)} width={300}/>*/}
                {/*        /!*<SavingsChartForCluster clusterId={1} clusterName="Home Solutions"/>*!/*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <Typography variant="h3" component="h3" className="dashboard-title">*/}
                {/*            Food Service Systems*/}
                {/*        </Typography>*/}
                {/*        <BeforeAfterChart data={C.getContributionsPerClusterCmp(2)} width={300}/>*/}
                {/*        /!*<SavingsChartForCluster clusterId={2} clusterName="FoodService Systems"/>*!/*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <Typography variant="h3" component="h3" className="dashboard-title">*/}
                {/*            Coffee Systems*/}
                {/*        </Typography>*/}
                {/*        <BeforeAfterChart data={C.getContributionsPerClusterCmp(3)} width={300}/>*/}
                {/*        /!*<SavingsChartForCluster clusterId={3} clusterName="Coffee Systems"/>*!/*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Grid>
        </Grid>
    </div>
});
