import React from "react";
import './SingleReviewButtons.scss'
import {Grid, IconButton, Tooltip} from "@mui/material";
import {AcceptButtons} from "./AcceptButtons";
import {SinglePartReviewStateVisual} from "../../classes/CategorizationReviewVisualStates";
import {CheckCircle, Visibility} from "@mui/icons-material";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {PartRowState} from "../../classes/PartRowState";
import {useStores} from "../../../../stores";

type Props = {
    onAccept?: () => void
    state: SinglePartReviewStateVisual
    editAllowed: boolean
    part: PartRowState
}
export const SingleReviewButtons: React.FC<Props> = ({onAccept, state, editAllowed, part}) => {
    const {categorizationReviewStore} = useStores();
    const isAccept = state.reviewChoice === ReviewChoice.ACCEPT;
    let disableAcceptReason: string | undefined = undefined
    let disableEditReason: string | undefined = undefined
    if (state.approvalState === 'PENDING') {
        disableAcceptReason = "Cannot accept already pending changes"
        disableEditReason = "Cannot re-categorize pending changes"
    }
    if (state.approvalState === 'APPROVED') {
        disableAcceptReason = "Cannot accept already approved changes"
        disableEditReason = "Cannot re-categorize approved changes"
    }

    return (
        <Grid container alignItems="center" justifyContent="space-evenly" className="single-review-buttons">
            <Grid item xs={6}>
                {!state.isHidden ? (<AcceptButtons
                    isAccept={isAccept}
                    onAccept={onAccept}
                    disabled={state.isHidden}
                />) : (
                    <Tooltip title={disableAcceptReason} arrow>
                    <span>
                        <IconButton className="disabled" disabled>
                            <CheckCircle/>
                        </IconButton>
                    </span>
                    </Tooltip>)}
            </Grid>
            <Grid item xs={6}>
                <IconButton onClick={() => {
                    categorizationReviewStore.setDetailModalPart(part)
                    categorizationReviewStore.toggleDetailModal()
                }}>
                    <Visibility className={'buttonIcon detail'}/>
                </IconButton>
            </Grid>
            {/*{editAllowed &&*/}
            {/*    <Grid item xs={3}>*/}
            {/*        <Tooltip title={disableEditReason} arrow>*/}
            {/*            <span>*/}
            {/*    <IconButton disabled={!editAllowed}>*/}
            {/*        <Edit className={'buttonIcon edit'}/>*/}
            {/*    </IconButton>*/}
            {/*            </span>*/}
            {/*        </Tooltip>*/}
            {/*    </Grid>}*/}
        </Grid>
    );
}
