/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";
import {ONLY_FOR_US} from "../build";
export const C: AppConfiguration = {
    package: 'cleanup',
    dashboardVariant: 'simple',
    menu: 'hidden',
    profile: {
        p_name_col_name: 'Name',
        p_description_col_name: 'Description',
        searchConfiguration: 'default_p_desc',
        locale: 'nl-nl',
        currency: 'EUR',
        taxonomy_builder_only_n_cats: true,
        allowIncompleteTaxonomy: true,

        enableDataIngestion: ONLY_FOR_US,
        enableTaxonomyIngestion: ONLY_FOR_US,
    },
}
