import {makeAutoObservable} from "mobx";
import {
    Category,
    Cluster,
    Company,
    Division,
    InitiativeFinancial,
} from "../../ApiTypes";
import {InitiativeInstanceStore} from "./InitiativeInstanceStore";

export const financial_amount_attrs = ['baseline_spend', 'investment', 'severance',
    'saving_amount',
    'fiscal_year_saving_amount', 'normal_year_saving_amount', 'fiscal_carry_over_saving_amount', 'normal_carry_over_saving_amount',
    'avoidance_amount',
    'fiscal_year_avoidance_amount', 'normal_year_avoidance_amount', 'fiscal_carry_over_avoidance_amount', 'normal_carry_over_avoidance_amount',
];

export class InitiativeFinancialController {
    _percentage: number = 0;

    constructor(public iis: InitiativeInstanceStore, public index: number) {
        makeAutoObservable(this);
        if (!this.instance.affected_months)
            this.instance.affected_months = 12;
    }

    get instance(): InitiativeFinancial {
        return this.iis.instance.financial![this.index];
    }

    get percentage(): number {
        // if (this.index === 0) return 100;
        return this._percentage;
    }

    set percentage(value: number) {
        // if (this.index === 0) return;
        if (value > 100 || value < 0) return;
        this._percentage = Math.round(100 * value) / 100;
        const sum_percentages = this.iis.f_c.filter((f_c) => f_c.index > 0).reduce((sum, item) => sum + item.percentage, 0);
        if (sum_percentages !== 100)
            this.iis.w_saving_split_sum = sum_percentages;
        else
            this.iis.w_saving_split_sum = undefined;
    }

    convertFromDefaultCurrency() {
        if (!this.iis.profileStore.currencySymbol ||
            this.iis.ccStore.getCurrencyCode(this.instance.currency_id ?? 0, '') === this.iis.profileStore.currencySymbol)
            return;
        financial_amount_attrs.forEach((attr) => {
            this.instance[attr] = this.iis.ccStore.convertFromCurrency(
                this.instance[attr] / 1000,
                this.iis.profileStore.currencySymbol!,
                this.instance.currency_id ?? 0);
        });
    }

    setValuesBasedOnSplitPercentage() {
        // Usage of parseFloat is due to some js operational issues. e.g. 1-0.9=0.09999999999999998, 3570 / 100 / 100 = 0.35700000000000004
        // Ratio values
        financial_amount_attrs.forEach((attr) => {
            this.instance[attr] = parseFloat((this.percentage * this.iis.f_c[0].instance[attr] / 100).toFixed(4));
        });
        // Copy values
        this.instance.initiative = this.iis.f_c[0].instance.initiative;
        this.instance.divisions = this.iis.f_c[0].instance.divisions;
        this.instance.currency_id = this.iis.f_c[0].instance.currency_id;
        this.instance.saving_method = this.iis.f_c[0].instance.saving_method;
        this.instance.parent = this.iis.f_c[0].instance.parent;
        this.instance.active = this.iis.f_c[0].instance.active;
        this.instance.affected_months = this.iis.f_c[0].instance.affected_months;
    }

    setCombinedSplitValues() {
        if (this.index !== 0) return;
        financial_amount_attrs.forEach((attr) => {
            this.iis.f_c.filter((f_c) => f_c.index > 0).forEach((item) => {
                this.instance[attr] += Number(item.instance[attr]);
            });
            this.instance[attr] = Math.round(this.instance[attr] * 100) / 100;
        });
        // Same values
        this.instance.initiative = this.iis.f_c[1].instance.initiative;
        this.instance.divisions = this.iis.f_c[1].instance.divisions;
        this.instance.currency_id = this.iis.f_c[1].instance.currency_id;
        this.instance.saving_method = this.iis.f_c[1].instance.saving_method;
        this.instance.parent = this.iis.f_c[1].instance.parent;
        this.instance.active = this.iis.f_c[1].instance.active;
        this.instance.affected_months = this.iis.f_c[1].instance.affected_months;
        // Combined unique
        this.instance.companies = [];
        this.iis.f_c.filter((f_c) => f_c.index > 0).forEach((f_c) =>
            f_c.instance.companies.forEach((c_id) => {
                if (!this.instance.companies.includes(c_id)) this.instance.companies.push(c_id)
            })
        );
        this.instance.categories = [];
        this.iis.f_c.filter((f_c) => f_c.index > 0).forEach((f_c) =>
            f_c.instance.categories.forEach((c_id) => {
                if (!this.instance.categories.includes(c_id)) this.instance.categories.push(c_id)
            })
        );
    }

    set affectedMonths(value: number) {
        this.instance.affected_months = value;
    }

    set currencyId(value: number) {
        this.instance.currency_id = value;
    }

    set savingAmount(value: number) {
        this.instance.saving_amount = value;
        this.fillSavings();
    }

    fillSavings(force = false) {
        const spm = (this.instance.affected_months ?? 0) > 0 ? ((this.instance.saving_amount ?? 0) / this.instance.affected_months!) : 0;
        const dd5m = this.iis.doi5Date.month() + 1;
        const companyFiscalYearStartMonth = this.iis.ccStore.getCompanyFiscalYearStartMonth(this.instance.companies?.at(0) ?? 0);
        if (force || !this.instance.fiscal_year_saving_amount)
            this.instance.fiscal_year_saving_amount = Math.round(100 * (dd5m <= companyFiscalYearStartMonth ?
                Math.min(this.instance.affected_months ?? 0, companyFiscalYearStartMonth - dd5m) * spm :
                Math.min(this.instance.affected_months ?? 0, 12 - dd5m) * spm)) / 100;
        if (force || !this.instance.normal_year_saving_amount)
            this.instance.normal_year_saving_amount = Math.round(100 * Math.min(this.instance.affected_months ?? 0, 12 - dd5m) * spm) / 100;
        if (force || !this.instance.fiscal_carry_over_saving_amount)
            this.instance.fiscal_carry_over_saving_amount = Math.round(100 * ((this.instance.saving_amount ?? 0) -
                this.instance.fiscal_year_saving_amount)) / 100;
        if (force || !this.instance.normal_carry_over_saving_amount)
            this.instance.normal_carry_over_saving_amount = Math.round(100 * ((this.instance.saving_amount ?? 0) -
                this.instance.normal_year_saving_amount)) / 100;
        if (force || !this.instance.saving_percentage)
            this.instance.saving_percentage = Math.round(100 * ((this.instance.baseline_spend ?? 0) > 0 ?
                (this.instance.saving_amount ?? 0) / this.instance.baseline_spend! : 0));
        if (force || !this.instance.carry_over_saving_percentage)
            this.instance.carry_over_saving_percentage = Math.round(100 * ((this.instance.baseline_spend ?? 0) > 0 ?
                this.instance.normal_carry_over_saving_amount / this.instance.baseline_spend! : 0));
    }

    set fiscalYearSavingAmount(value: number) {
        this.instance.fiscal_year_saving_amount = value;
    }

    set fiscalCarryOverSavingAmount(value: number) {
        this.instance.fiscal_carry_over_saving_amount = value;
    }

    set normalYearSavingAmount(value: number) {
        this.instance.normal_year_saving_amount = value;
    }

    set normalCarryOverSavingAmount(value: number) {
        this.instance.normal_carry_over_saving_amount = value;
    }

    set savingPercentage(value: number) {
        this.instance.saving_percentage = value;
    }

    // set carryOverSavingPercentage(value: number) {
    //     this.instance.carry_over_saving_percentage = value;
    // }

    set avoidanceAmount(value: number) {
        this.instance.avoidance_amount = value;
        this.fillAvoidances();
    }

    fillAvoidances(force = false) {
        const spm = (this.instance.affected_months ?? 0) > 0 ? ((this.instance.avoidance_amount ?? 0) / this.instance.affected_months!) : 0;
        const dd5m = this.iis.doi5Date.month() + 1;
        const companyFiscalYearStartMonth = this.iis.ccStore.getCompanyFiscalYearStartMonth(this.instance.companies?.at(0) ?? 0);
        if (force || !this.instance.fiscal_year_avoidance_amount)
            this.instance.fiscal_year_avoidance_amount = Math.round(100 * (dd5m <= companyFiscalYearStartMonth ?
                Math.min(this.instance.affected_months ?? 0, companyFiscalYearStartMonth - dd5m) * spm :
                Math.min(this.instance.affected_months ?? 0, 12 - dd5m) * spm)) / 100;
        if (force || !this.instance.normal_year_avoidance_amount)
            this.instance.normal_year_avoidance_amount = Math.round(100 * Math.min(this.instance.affected_months ?? 0, 12 - dd5m) * spm) / 100;
        if (force || !this.instance.fiscal_carry_over_avoidance_amount)
            this.instance.fiscal_carry_over_avoidance_amount = Math.round(100 * ((this.instance.avoidance_amount ?? 0) -
                this.instance.fiscal_year_avoidance_amount)) / 100;
        if (force || !this.instance.normal_carry_over_avoidance_amount)
            this.instance.normal_carry_over_avoidance_amount = Math.round(100 * ((this.instance.avoidance_amount ?? 0) -
                this.instance.normal_year_avoidance_amount)) / 100;
        if (force || !this.instance.avoidance_percentage)
            this.instance.avoidance_percentage = Math.round(100 * ((this.instance.baseline_spend ?? 0) > 0 ?
                (this.instance.avoidance_amount ?? 0) / this.instance.baseline_spend! : 0));
        if (force || !this.instance.carry_over_avoidance_percentage)
            this.instance.carry_over_avoidance_percentage = Math.round(100 * ((this.instance.baseline_spend ?? 0) > 0 ?
                this.instance.normal_carry_over_avoidance_amount / this.instance.baseline_spend! : 0));
    }

    set fiscalYearAvoidanceAmount(value: number) {
        this.instance.fiscal_year_avoidance_amount = value;
    }

    set fiscalCarryOverAvoidanceAmount(value: number) {
        this.instance.fiscal_carry_over_avoidance_amount = value;
    }

    set normalYearAvoidanceAmount(value: number) {
        this.instance.normal_year_avoidance_amount = value;
    }

    set normalCarryOverAvoidanceAmount(value: number) {
        this.instance.normal_carry_over_avoidance_amount = value;
    }

    set avoidancePercentage(value: number) {
        this.instance.avoidance_percentage = value;
    }

    // set carryOverAvoidancePercentage(value: number) {
    //     this.instance.carry_over_avoidance_percentage = value;
    // }

    set baselineSpend(value: number) {
        this.instance.baseline_spend = value;
    }

    set investment(value: number) {
        this.instance.investment = value;
    }

    set severance(value: number) {
        this.instance.severance = value;
    }

    get combinedClusters(): Cluster[] {
        return this.iis.ccStore.companyRM.result?.filter(
            (company) => (this.instance.companies?.indexOf(company.id) ?? -1) > -1
        ).map(
            (company) => company.cluster
        ).filter(
            (value, index, array) => array.map((cluster) => cluster.id).indexOf(value.id) === index
        ) ?? [];
    }

    isCheckedClusters(id: number) {
        const allCompanyIds = this.iis.ccStore.companyRM.result?.filter(
            (company) => company.cluster.id === id
        ).map(
            (company) => company.id
        ) ?? [];
        return allCompanyIds.length > 0 && (allCompanyIds.every(
            (company_id) => (this.instance.companies?.indexOf(company_id) ?? -1) > -1
        ) ?? false);
    }

    isIndeterminateCluster(id: number) {
        const allCompanyIds = this.iis.ccStore.companyRM.result?.filter(
            (company) => company.cluster.id === id
        ).map(
            (company) => company.id
        ) ?? [];
        return allCompanyIds.filter((id) => (this.instance.companies?.indexOf(id) ?? -1) > -1).length > 0 &&
            allCompanyIds.filter((id) => (this.instance.companies?.indexOf(id) ?? -1) === -1).length > 0;
    }

    setClusters(value: Cluster[], remove: boolean) {
        if (remove) {
            const removedClusterIds = this.combinedClusters.filter(
                (cluster) => !(value.map((c) => c.id).includes(cluster.id))
            ).map(
                (cluster) => cluster.id
            );
            this.iis.ccStore.companyRM.result?.filter(
                (company) => removedClusterIds.includes(company.cluster.id)
            ).forEach(
                (item) => this.instance.companies?.splice(this.instance.companies?.indexOf(item.id), 1)
            )
        } else {
            const addedClusterIds = value.filter(
                (cluster) => !(this.combinedClusters.map((c) => c.id).includes(cluster.id))
            ).map(
                (cluster) => cluster.id
            );
            this.instance.companies = this.iis.ccStore.companyRM.result?.filter(
                (company) => addedClusterIds.includes(company.cluster.id) || this.instance.companies?.includes(company.id)
            ).map(
                (cluster) => cluster.id
            ).filter(
                (value, index, array) => array.indexOf(value) === index
            ) ?? [];
        }
    }

    get companyValues(): Company[] {
        return this.iis.ccStore.companyRM.result?.filter(
            (company) => (this.instance.companies?.indexOf(company.id) ?? -1) > -1
        ) ?? [];
    }

    set companies(value: number[]) {
        this.instance.companies = value;
        if (this.index === 0 && this.iis.f_c.length > 1) {
            let companies: number[] = [];
            this.iis.f_c.filter((f_c) => f_c.index > 0).forEach((f_c) =>
                f_c.instance.companies.forEach((c_id) => {
                    if (!companies.includes(c_id)) companies.push(c_id)
                })
            );
            this.iis.w_saving_split_removed_companies = [];
            companies.forEach((c_id) => {
                if (!this.instance.companies.includes(c_id)) {
                    this.iis.w_saving_split_removed_companies!.push(c_id);
                }
            });
            if (this.iis.w_saving_split_removed_companies?.length === 0) this.iis.w_saving_split_removed_companies = undefined;
        }
    }

    get categoryValues(): Category[] {
        return this.iis.ccStore.categoryRM.result?.filter(
            (category) => (this.instance.categories?.indexOf(category.id) ?? -1) > -1
        ) ?? [];
    }

    set categories(value: number[]) {
        this.instance.categories = value;
        if (this.index === 0 && this.iis.f_c.length > 1) {
            let categories: number[] = [];
            this.iis.f_c.filter((f_c) => f_c.index > 0).forEach((f_c) =>
                f_c.instance.categories.forEach((c_id) => {
                    if (!categories.includes(c_id)) categories.push(c_id)
                })
            );
            categories.forEach((c_id) => {
                if (!this.instance.categories.includes(c_id)) {
                    if (this.iis.w_saving_split_removed_categories === undefined) this.iis.w_saving_split_removed_categories = [];
                    this.iis.w_saving_split_removed_categories.push(c_id);
                }
            });
            if (this.iis.w_saving_split_removed_categories?.length === 0) this.iis.w_saving_split_removed_categories = undefined;
        }
    }

    get divisionValues(): Division[] {
        return this.iis.ccStore.divisionRM.result?.filter(
            (division) => (this.instance.divisions?.indexOf(division.id) ?? -1) > -1
        ) ?? [];
    }

    set divisions(value: number[]) {
        this.instance.divisions = value;
    }
}
