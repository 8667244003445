import React from "react";
import {useStores} from "../stores";
import {Navigate, useLocation} from "react-router-dom";
import {LoginRouteState} from "../stores/managers/AuthManager";


export type RequireAuthProps = {
    loginUrl: string
}

/**
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 */
export const RequireAuth: React.FC<RequireAuthProps> = ({children, loginUrl}) => {
    const {authStore} = useStores();
    const location = useLocation();
    const loggedIn = authStore.isLoggedIn();
    if (!loggedIn) {
        const loginState: LoginRouteState = {
            state: 'login',
            sourceUrl: location.pathname,
        }
        return <Navigate to={loginUrl} replace state={loginState}/>;
    }
    return <>{children}</>;
}
