import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {
    Alert,
    Autocomplete,
    Button, Checkbox,
    FormControl,
    FormHelperText,
    Grid, IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField, Tooltip, Typography
} from "@mui/material";
import {useSitStores} from "../../../../stores";
import {CalendarIcon, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Add, Delete} from "@mui/icons-material";
import PercentIcon from '@mui/icons-material/Percent';
import CalculateIcon from '@mui/icons-material/Calculate';
import dayjs from "dayjs";
import {Category, category_readable_str, Company, DoiStatus} from "../../../ApiTypes";
import {SitCurrency} from "../../../components/SitCurrency";

export const InitiativeFinancialForm: React.FC = observer(() => {
        const {ccStore, initiativeInstanceStore} = useSitStores();
        const [doi5Open, setDoi5Open] = useState(false);

        return <Grid container spacing={3}>
            <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={initiativeInstanceStore.disabled}
                            label={"\"" + DoiStatus[5 - 1] + "\" Project Date"}
                            value={initiativeInstanceStore.doi5Date}
                            open={doi5Open}
                            slotProps={(!initiativeInstanceStore.creating && initiativeInstanceStore.instance.doi_status === 4
                                && initiativeInstanceStore.doi5SecondDate === null) ? {
                                textField: {
                                    InputProps: {
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<Add/>}
                                                    onClick={() => {
                                                        initiativeInstanceStore.doi5SecondDate = dayjs(new Date());
                                                    }}>
                                                    Add another doi 5
                                                </Button>
                                            </InputAdornment>
                                    }
                                }
                            } : (initiativeInstanceStore.creating ? {
                                textField: {
                                    InputProps: {
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Tooltip title="Click to distribute project dates evenly">
                                                    <IconButton onClick={() => {
                                                        initiativeInstanceStore.distributeDois();
                                                        setDoi5Open(false);
                                                    }}>
                                                        <CalculateIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <IconButton onClick={() => setDoi5Open(!doi5Open)}>
                                                    <CalendarIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                    }
                                }
                            } : {
                                textField: {
                                    InputProps: {
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setDoi5Open(!doi5Open)}>
                                                    <CalendarIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                    }
                                }
                            })}
                            onChange={(value) => {
                                if (value !== null) {
                                    initiativeInstanceStore.doi5Date = value;
                                    setDoi5Open(false);
                                }
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>

            {(initiativeInstanceStore.doi5SecondDate !== null) && <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={initiativeInstanceStore.disabled}
                            label="New DOI5"
                            value={initiativeInstanceStore.doi5SecondDate}
                            onChange={(value) => {
                                if (value !== null)
                                    initiativeInstanceStore.doi5SecondDate = value;
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>}

            <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                    <TextField
                        disabled={initiativeInstanceStore.disabled}
                        id="affected_months"
                        variant="outlined"
                        label="Affected period"
                        required
                        value={initiativeInstanceStore.f_c[0].instance.affected_months ?? 0}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">months</InputAdornment>
                        }}
                        onChange={(event) =>
                            initiativeInstanceStore.f_c[0].affectedMonths = parseFloat(event.target.value) || 0
                        }/>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select
                        disabled={initiativeInstanceStore.disabled}
                        labelId="currency-label"
                        id="currency"
                        value={!(initiativeInstanceStore.f_c[0].instance.currency_id) ? '' : initiativeInstanceStore.f_c[0].instance.currency_id}
                        label="Currency"
                        onChange={(event) => {
                            if (typeof event.target.value === 'number')
                                initiativeInstanceStore.f_c[0].currencyId = event.target.value;
                        }}>
                        {ccStore.currencyRM.result?.map((currency) => {
                            return <MenuItem
                                key={currency.id}
                                value={currency.id}>{currency.code}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                {ccStore.convertToCurrency(1, initiativeInstanceStore.f_c[0].instance.currency_id,
                        initiativeInstanceStore.profileStore.currencySymbol ?? '') === 0 &&
                    <Alert severity="error" color="error">
                        Conversion rate not found! You must add a rate for selected pair in settings.
                    </Alert>}
            </Grid>

            <Grid item xs={6}>
                <SitCurrency
                    disabled={initiativeInstanceStore.disabled}
                    label="Baseline spend"
                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                    value={initiativeInstanceStore.f_c[0].instance.baseline_spend ?? 0}
                    onChange={(event) =>
                        initiativeInstanceStore.f_c[0].baselineSpend = parseFloat(event.target.value) || 0
                    }/>
            </Grid>

            <Grid item xs={6}>
                <SitCurrency
                    disabled={initiativeInstanceStore.disabled}
                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                    label="Required Investment"
                    value={initiativeInstanceStore.f_c[0].instance.investment ?? 0}
                    onChange={(event) =>
                        initiativeInstanceStore.f_c[0].investment = parseFloat(event.target.value) || 0
                    }/>
            </Grid>
            <Grid item xs={6}>
                <SitCurrency
                    disabled={initiativeInstanceStore.disabled}
                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                    label="Severance"
                    value={initiativeInstanceStore.f_c[0].instance.severance ?? 0}
                    onChange={(event) =>
                        initiativeInstanceStore.f_c[0].severance = parseFloat(event.target.value) || 0
                    }/>
            </Grid>

            <Grid item xs={12}>
                <hr/>
            </Grid>

            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            label="Total Cost Savings"
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            value={initiativeInstanceStore.f_c[0].instance.saving_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].savingAmount = parseFloat(event.target.value) || 0
                            }
                            endAd={<Tooltip title="Click to fill in calculations"><span>
                            <IconButton
                                disabled={initiativeInstanceStore.disabled}
                                onClick={() => initiativeInstanceStore.f_c[0].fillSavings(true)}>
                                <CalculateIcon/>
                            </IconButton></span>
                            </Tooltip>}/>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            label="Rolling Year Cost Saving"
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            value={initiativeInstanceStore.f_c[0].instance.normal_year_saving_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].normalYearSavingAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>12 Month Running</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Carryover Cost Saving"
                            value={initiativeInstanceStore.f_c[0].instance.normal_carry_over_saving_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].normalCarryOverSavingAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>12 Month Running</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Fiscal Year Cost Saving"
                            value={initiativeInstanceStore.f_c[0].instance.fiscal_year_saving_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].fiscalYearSavingAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>Finance Method</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Carryover Cost Saving"
                            value={initiativeInstanceStore.f_c[0].instance.fiscal_carry_over_saving_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].fiscalCarryOverSavingAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>Finance Method</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={initiativeInstanceStore.disabled}
                                id="saving_percentage"
                                variant="outlined"
                                label="Fiscal Year Cost Saving Percentage"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <PercentIcon/>
                                    </InputAdornment>
                                }}
                                value={initiativeInstanceStore.f_c[0].instance.saving_percentage ?? 0}
                                onChange={(event) =>
                                    initiativeInstanceStore.f_c[0].savingPercentage = parseFloat(event.target.value) || 0
                                }/>
                        </FormControl>
                    </Grid>

                    {/*<Grid item xs={6}>*/}
                    {/*    <FormControl fullWidth>*/}
                    {/*        <TextField*/}
                    {/*            disabled={initiativeInstanceStore.disabled}*/}
                    {/*            id="carry_over_saving_percentage"*/}
                    {/*            variant="outlined"*/}
                    {/*            label="Carryover Cost Saving Percentage"*/}
                    {/*            InputProps={{*/}
                    {/*                endAdornment: <InputAdornment position="end">*/}
                    {/*                    <PercentIcon/>*/}
                    {/*                </InputAdornment>*/}
                    {/*            }}*/}
                    {/*            value={fc.instance.carry_over_saving_percentage ?? 0}*/}
                    {/*            onChange={(event) =>*/}
                    {/*                fc.carryOverSavingPercentage = parseFloat(event.target.value) || 0*/}
                    {/*            }/>*/}
                    {/*    </FormControl>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Total Cost Avoidance"
                            value={initiativeInstanceStore.f_c[0].instance.avoidance_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].avoidanceAmount = parseFloat(event.target.value) || 0
                            }
                            endAd={<Tooltip title="Click to fill in calculations"><span>
                            <IconButton
                                disabled={initiativeInstanceStore.disabled}
                                onClick={() => initiativeInstanceStore.f_c[0].fillAvoidances(true)}>
                                <CalculateIcon/>
                            </IconButton></span>
                            </Tooltip>}/>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Rolling Year Cost Avoidance"
                            value={initiativeInstanceStore.f_c[0].instance.normal_year_avoidance_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].normalYearAvoidanceAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>12 Month Running</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Carryover Cost Avoidance"
                            value={initiativeInstanceStore.f_c[0].instance.normal_carry_over_avoidance_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].normalCarryOverAvoidanceAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>12 Month Running</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Fiscal Year Cost Avoidance"
                            value={initiativeInstanceStore.f_c[0].instance.fiscal_year_avoidance_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].fiscalYearAvoidanceAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>Finance Method</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Carryover Cost Avoidance"
                            value={initiativeInstanceStore.f_c[0].instance.fiscal_carry_over_avoidance_amount ?? 0}
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].fiscalCarryOverAvoidanceAmount = parseFloat(event.target.value) || 0
                            }/>
                        <FormHelperText>Finance Method</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={initiativeInstanceStore.disabled}
                                id="avoidance_percentage"
                                variant="outlined"
                                label="Fiscal Year Cost Avoidance Percentage"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <PercentIcon/>
                                    </InputAdornment>
                                }}
                                value={initiativeInstanceStore.f_c[0].instance.avoidance_percentage ?? 0}
                                onChange={(event) =>
                                    initiativeInstanceStore.f_c[0].avoidancePercentage = parseFloat(event.target.value) || 0
                                }/>
                        </FormControl>
                    </Grid>

                    {/*<Grid item xs={6}>*/}
                    {/*    <FormControl fullWidth>*/}
                    {/*        <TextField*/}
                    {/*            disabled={initiativeInstanceStore.disabled}*/}
                    {/*            id="carry_over_avoidance_percentage"*/}
                    {/*            variant="outlined"*/}
                    {/*            label="Carryover Cost Avoidance Percentage"*/}
                    {/*            InputProps={{*/}
                    {/*                endAdornment: <InputAdornment position="end">*/}
                    {/*                    <PercentIcon/>*/}
                    {/*                </InputAdornment>*/}
                    {/*            }}*/}
                    {/*            value={fc.instance.carry_over_avoidance_percentage ?? 0}*/}
                    {/*            onChange={(event) =>*/}
                    {/*                fc.carryOverAvoidancePercentage = parseFloat(event.target.value) || 0*/}
                    {/*            }/>*/}
                    {/*    </FormControl>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>

            {(initiativeInstanceStore.f_c[0].instance.companies.length > 1 ||
                initiativeInstanceStore.f_c[0].instance.categories.length > 1 ||
                initiativeInstanceStore.f_c.length > 1) && <>
                <Grid item xs={12}>
                    <hr/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">Saving Split</Typography>
                </Grid>
                {(initiativeInstanceStore.w_saving_split_sum !== undefined) && <Grid item xs={12}>
                    <Alert severity="warning" color="error">
                        Sum of percentages is not 100: {initiativeInstanceStore.w_saving_split_sum}
                    </Alert>
                </Grid>}

                {(initiativeInstanceStore.f_c[0].instance.companies.length > 1 ||
                        initiativeInstanceStore.f_c[0].instance.categories.length > 1) &&
                    initiativeInstanceStore.f_c.length === 1 && <Grid item xs={12}>
                        <Alert severity="warning" color="error">
                            You have more than
                            one {initiativeInstanceStore.f_c[0].instance.companies.length > 1 ? 'company' :
                            (initiativeInstanceStore.f_c[0].instance.categories.length > 1 ? 'category' : 'item')} in
                            financial information, you should split them.
                        </Alert>
                    </Grid>}

                <Grid item xs={12}>
                    {initiativeInstanceStore.f_c.filter((_f_c, i) => i > 0).map((f_c, index) =>
                        <Grid container spacing={1} key={index}>
                            <Grid item xs={4}>
                                <FormControl fullWidth margin="dense">
                                    <Autocomplete
                                        disabled={initiativeInstanceStore.disabled}
                                        multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                        id="company"
                                        limitTags={1}
                                        options={initiativeInstanceStore.f_c[0].companyValues ?? ([] as Company[])}
                                        getOptionLabel={(option) => option.title}
                                        value={f_c.companyValues}
                                        disableCloseOnSelect
                                        renderInput={(params) =>
                                            <TextField {...params} variant="outlined" label="Company" multiline={false}
                                                       placeholder={f_c.companyValues.length > 0 ? "" : "Select Company"}/>
                                        }
                                        renderOption={(props, option) =>
                                            <li key={option.id} {...props}>
                                                <Checkbox
                                                    checked={(f_c.instance.companies?.indexOf(option.id) ?? -1) > -1}/>
                                                {option.title}
                                            </li>}
                                        onChange={(_event, value) =>
                                            f_c.companies = value.map((company) => company.id)
                                        }/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth margin="dense">
                                    <Autocomplete
                                        disabled={initiativeInstanceStore.disabled}
                                        multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                        id="category"
                                        limitTags={1}
                                        options={initiativeInstanceStore.f_c[0].categoryValues ?? ([] as Category[])}
                                        getOptionLabel={(option) => category_readable_str(option)}
                                        value={f_c.categoryValues}
                                        disableCloseOnSelect
                                        renderInput={(params) =>
                                            <TextField {...params} variant="outlined" label="Category" multiline={false}
                                                       placeholder={f_c.companyValues.length > 0 ? "" : "Select Categories"}/>
                                        }
                                        renderOption={(props, option) =>
                                            <li key={option.id} {...props}>
                                                <Checkbox
                                                    checked={(f_c.instance.categories?.indexOf(option.id) ?? -1) > -1}/>
                                                {category_readable_str(option)}
                                            </li>}
                                        onChange={(_event, value) =>
                                            f_c.categories = value.map((category) => category.id)
                                        }/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        disabled={initiativeInstanceStore.disabled}
                                        id="saving_split_percentage"
                                        variant="outlined"
                                        label="Saving Percentage"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <PercentIcon/>
                                            </InputAdornment>
                                        }}
                                        value={f_c.percentage}
                                        onChange={(event) =>
                                            f_c.percentage = parseFloat(event.target.value) || 0
                                        }/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    disabled={initiativeInstanceStore.disabled}
                                    className="mt-3 w-100"
                                    onClick={() => initiativeInstanceStore.removeFinancial(f_c.index)}>
                                    <Delete/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={initiativeInstanceStore.disabled}
                        fullWidth
                        variant="outlined"
                        startIcon={<Add/>}
                        onClick={() => initiativeInstanceStore.addFinancial()}
                    >New Saving Split</Button>
                </Grid>
            </>
            }
        </Grid>
    })
;