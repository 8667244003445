import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {MithraEchart} from "../../../services/echarts/MithraEchart";
import {useSitStores} from "../../../stores";
import {EChartContextType} from "../../../services/echarts/EChartProvider";
import {EChartsType} from "echarts/types/dist/shared";
import {EchartSitDefaultColor, EchartSitDefaultLegend} from "../../../services/echarts/EchartSitCommonConfig";
import {SIT_TEST} from "../../SitApit";

function init(echart: EChartContextType, root: HTMLElement, data: any[]): EChartsType {
    const chart = echart.init(root, 'mithra', {renderer: 'svg', width: 1200, height: 200,});
    chart.setOption({
        tooltip: {
            trigger: 'axis',
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {show: true},
            },
        },
        legend: {
            data: ['Approved', 'Pending'],
            ...EchartSitDefaultLegend
        },
        color: EchartSitDefaultColor,
        xAxis: {type: 'value'},
        yAxis: {type: 'category'},
        dataset: data,
        series: [
            {
                name: 'Approved',
                type: 'bar',
                encode: {x: 'Amount', y: 'Type'},
                datasetId: 'DOI5 total aggregated approved saving and target',
                stack: 'Totals',
                color: '#091344',
            },
            {
                name: 'Pending',
                type: 'bar',
                encode: {x: 'Amount', y: 'Type'},
                datasetId: 'DOI5 total aggregated pending saving and target ',
                stack: 'Totals',
                color: '#ff3435',
            }
        ],
    })
    return chart;
}

export const TargetVsActualVertical: React.FC = observer(() => {
    const {fbStore} = useSitStores();

    const data = [
        {
            id: 'DOI5 total aggregated approved saving and target',
            dimensions: [
                {name: 'Type'},
                {name: 'Amount', type: 'number'},
            ],
            source: [
                [
                    'Saving',
                    fbStore.approvedContribution,
                ],
                [
                    'Target',
                    fbStore.totalTargetOfYear,
                ],
            ]
        },
        {
            id: 'DOI5 total aggregated pending saving and target ',
            dimensions: [
                {name: 'Type'},
                {name: 'Amount', type: 'number'},
            ],
            source: [
                [
                    'Saving',
                    fbStore.notApprovedContribution,
                ],
                [
                    'Target',
                    0,
                ],
            ]
        },
    ]

    return <Grid container>
        <Grid item xs={12}>
            {SIT_TEST && '<TargetVsActualVertical/>'}
            <MithraEchart
                // alwaysRerender={() => {
                // }}
                initChart={init}
                data={data}
                autoUpdateDataset
            />
        </Grid>
    </Grid>
})